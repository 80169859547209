import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import type MapView from '@arcgis/core/views/MapView';
import type SceneView from '@arcgis/core/views/SceneView';

const CAScaleBarWidget = (view: MapView | SceneView) => {
    return new ScaleBar({
        view,
        unit: 'dual'
    } as __esri.ScaleBarProperties);
};

export default CAScaleBarWidget;
