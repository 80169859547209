import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
import type MapView from '@arcgis/core/views/MapView';
import type SceneView from '@arcgis/core/views/SceneView';
import LocalBasemapsSource from "@arcgis/core/widgets/BasemapGallery/support/LocalBasemapsSource";
import Basemap from "@arcgis/core/Basemap";

const CABasemapWidget = (view: MapView | SceneView) => {

    let navigationDarkPlaces = new Basemap({
        portalItem: {
          id: "50dde540835946c6a37e54d008c3bbf4" //Navigation (Dark - Places
        }
       });

    const source = new LocalBasemapsSource({
        basemaps: [
            Basemap.fromId("satellite"),          // Imagery
            Basemap.fromId("hybrid"),             // Imagery Hybrid
            Basemap.fromId("streets-vector"),     // Streets
            Basemap.fromId("streets-navigation-vector"), // Navigation
            Basemap.fromId("streets-night-vector"), // Streets (night)
            Basemap.fromId("gray-vector"),        // Light Gray Canvas
            Basemap.fromId("dark-gray-vector"),   // Dark Gray Canvas
            navigationDarkPlaces
        ]
    });

    const gallery = new BasemapGallery({
        view: view,
        source: source
    });

    return new Expand({
        view: view,
        content: gallery,
        expandTooltip: 'Basemaps'
    });
};

export default CABasemapWidget;
