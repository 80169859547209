import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { CXYLayerLabels } from '../../helpers/CALayerLabels';
import CXYPopup from '../../popups/CXYPopup';

export const bigBoxLayerTitle = 'Big Box Retail';

const CXBigBoxLayer = () => {
    return new FeatureLayer({
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/Collection_296192/FeatureServer/0',
        title: bigBoxLayerTitle,
        opacity: 1.0,
        visible: true,
        definitionExpression: '1<>1',
        labelsVisible: false,
        legendEnabled: false,
        labelingInfo: [CXYLayerLabels()],
        popupTemplate: CXYPopup()
    });
};

export default CXBigBoxLayer;
