import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import { getSupportingLayerId } from '../../helpers/CALayerID';

const CACrimeLayer = () => {
    const title = 'Crime Index';

    // const popupTemplate = new PopupTemplate({
    //     outFields: ['ID', 'NAME', 'CRMCYTOTC'],
    //     title: (feature: __esri.Feature) => {
    //         // NOTE: This is accessing a private property to get the layer name. Double check this still works when updating the Esri API
    //         const layerName = (feature.graphic as any).sourceLayer.title;

    //         if (layerName === 'County') {
    //             return feature.graphic.attributes.NAME;
    //         }

    //         if (layerName === 'ZIP Code') {
    //             return `${feature.graphic.attributes.ID} (${layerName})`;
    //         }

    //         return `${feature.graphic.attributes.NAME} (${layerName})`;
    //     },
    //     content:
    //         'In comparison to the national average of 100, the overall crime index in this area <strong>{CRMCYTOTC}</strong>.'
    // });

    const crimeLayer = new MapImageLayer({
        portalItem: {
            id: 'b3802d8a309544b791c2304fece864dc'
        },
        opacity: 0.5,
        id: getSupportingLayerId(title),
        visible: false,
        title,
        listMode: 'hide-children',
        // popupTemplate: popupTemplate
    });

    return crimeLayer;
};

export default CACrimeLayer;
