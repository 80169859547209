import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { CALayerLabels } from '../../helpers/CALayerLabels';
import CAInfoPopup from '../../popups/CAInfoPopup';
import { getCALayerId } from '../../helpers/CALayerID';

const CAClosedGroceryLayer = () => {
    const title = 'Closed Grocery';

    return new FeatureLayer({
        title,
        id: getCALayerId(title),
        visible: false,
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/Closed_Supermarkets/FeatureServer/0',
        labelingInfo: [CALayerLabels()],
        popupTemplate: CAInfoPopup()
    });
};

export default CAClosedGroceryLayer;
