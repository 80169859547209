import PopupTemplate from '@arcgis/core/PopupTemplate';
import CAInfoQuery from '../helpers/CAInfoQuery';
import { CAInfoContent } from './CAInfoPopup';
import { CAViewOnGooglePopupAction, CANearbyStatsPopupAction } from './CAPoupActions';

const CXYPopup = () => {
    return new PopupTemplate({
        outFields: ['*'],
        title: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;
            return `${atts.Label || atts.NAME}${atts.StoreNumber ? ' - ' + atts.StoreNumber : ''}`;
        },
        content: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;

            return CAInfoQuery(feature.graphic)
                .then(r => {
                    if (r.features.length) {
                        return CAInfoContent(r.features[0]);
                    } else {
                        const addressValues = [
                            atts.STREET_ADD || atts.Address,
                            `${atts.CITY || atts.City}, ${atts.STATE || atts.State}  ${atts.ZIP_CODE ||
                                atts.PostalCode}`
                        ];
                        return addressValues.join('<br>');
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        },
        actions: [CAViewOnGooglePopupAction(), CANearbyStatsPopupAction()]
    });
};

export default CXYPopup;
