import LayerList from '@arcgis/core/widgets/LayerList';
import Expand from '@arcgis/core/widgets/Expand';
import { SUPPORT_LAYER_ID_PREFIX } from '../helpers/CAConstants';
import { parcelsLayerTitle, parcelOwnerLabelClass } from '../layers/supporting/CAParcelsLayer';

const CALayerListSupporting = (view: __esri.MapView | __esri.SceneView) => {
    const supportingLayerList = new LayerList({
        view,
        listItemCreatedFunction: i => {
            const item = i.item as __esri.ListItem;

            if (!item.layer.id.startsWith('demo')) {
                const actionsSections = [
                    [
                        {
                            title: 'Increase opacity',
                            className: 'esri-icon-up',
                            id: 'increase-opacity'
                        },
                        {
                            title: 'Decrease opacity',
                            className: 'esri-icon-down',
                            id: 'decrease-opacity'
                        }
                    ]
                ] as any;

                // Add toggle labels action for parcels layer
                if (item.layer.title === parcelsLayerTitle) {
                    actionsSections.push([{
                        title: "Toggle Owner Labels",
                        className: "esri-icon-labels",
                        id: "toggle-owner-labels"
                    }]);
                }

                if (item.layer.type === 'group') { // don't include full extent button for group layers
                    item.actionsSections = actionsSections;
                } else {
                    const sections = [
                        [
                            {
                                title: 'Go to full extent',
                                className: 'esri-icon-zoom-out-fixed',
                                id: 'full-extent'
                            }
                        ],
                        ...actionsSections
                    ];

                    item.actionsSections = sections as any;
                }
            }
        }
    });

    supportingLayerList.on('trigger-action', evt => {
        if (evt.action.id === 'full-extent') {
            view.goTo(evt.item.layer.fullExtent);
        } else if (evt.action.id === 'increase-opacity') {
            if (evt.item.layer.opacity < 1) {
                evt.item.layer.opacity += 0.125;
            }
        } else if (evt.action.id === 'decrease-opacity') {
            if (evt.item.layer.opacity > 0) {
                evt.item.layer.opacity -= 0.125;
            }
        } else if (evt.action.id === 'toggle-owner-labels') {
            const layer = evt.item.layer as __esri.FeatureLayer;
            if (!layer.labelingInfo || layer.labelingInfo.length === 0) {
                layer.labelingInfo = [parcelOwnerLabelClass];
                layer.labelsVisible = true;
            } else {
                layer.labelsVisible = !layer.labelsVisible;
            }
        }
    });

    supportingLayerList.operationalItems.watch('length', () => {
        const layers = supportingLayerList.operationalItems.filter(i => i.layer.id.startsWith(SUPPORT_LAYER_ID_PREFIX));
        supportingLayerList.operationalItems.removeAll();
        supportingLayerList.operationalItems.addMany(layers);
    });

    return new Expand({
        view,
        content: supportingLayerList,
        expandTooltip: 'Supporting Layers'
    });
};

export default CALayerListSupporting;
