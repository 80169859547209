import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

export const bufferLayerTitle = 'Buffer';

const CABufferLayer = () => {
    return new GraphicsLayer({
        title: bufferLayerTitle,
        visible: true,
        opacity: 1.0,
        listMode: 'hide'
    });
};

export default CABufferLayer;
