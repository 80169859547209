import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import Color from '@arcgis/core/Color';
import type Point from '@arcgis/core/geometry/Point';
import { getCALayerId } from '../../helpers/CALayerID';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Button, Col, Row } from 'reactstrap';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { CATextSymbol } from '../../helpers/CALayerLabels';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';

const outFields = [
    'ACCURACY',
    'AUDITDATE',
    'CITY',
    'DESCLOCATION',
    'DEVELOPER',
    'DEVTYPE',
    'NAME',
    'NAMECENTER',
    'OBJECTID',
    'OPENDATE',
    'OPENDATEAPPROX',
    'PROJECTURL',
    'SIZESF',
    'SIZESFTYPE',
    'SOURCE',
    'STATE',
    'STATUS',
    'ZIP',
    'county',
    'NOTES'
];

/**
 * Returns a formatted link to the developers site
 * @param dev Name of the developer
 * @param link URL to the development site
 */
const getDevLink = (dev?: string, link?: string) => {
    if (dev && dev !== 'null') {
        // service may return a string called null, not an actual null.
        const devTitle = <div className="text-muted small">Developer</div>;
        if (link && link !== 'null') {
            return (
                <React.Fragment>
                    {devTitle}
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faBuilding} className="me-1 pgr-popup-link-icon" />
                        <span className="d-none d-md-inline">{dev}</span>
                    </a>
                </React.Fragment>
            );
        } else {
            return (
                <div>
                    {devTitle}
                    {dev}
                </div>
            );
        }
    }

    return undefined;
};

/**
 * Returns a formatted opendate given a timestamp. Otherwise returns undefined.
 * @param timestamp
 */
const getOpenDate = (timestamp?: number) => {
    if (timestamp && !isNaN(timestamp)) {
        const dt = DateTime.fromMillis(timestamp);
        return dt.toLocaleString(DateTime.DATE_MED);
    }

    return undefined;
};

/**
 * Returns a formatted string for the store size.
 * @param size Store Size
 * @param code Store size coded value domain code
 */
const getSize = (size?: number, code?: number) => {
    if (size) {
        const formattedSize =
            size.toLocaleString(undefined, {
                minimumFractionDigits: 0
            }) + ' SF';

        if (code && code === 1) {
            return `${formattedSize} (Approx.)`;
        } else {
            return formattedSize;
        }
    }

    return 'N/A';
};

const getDevType = (feature: __esri.Feature) => {
    const devType = isNaN(feature.graphic.attributes.DEVTYPE) // this is really strange. WinCo Foods status is decoded at some point but not sure where. Possibly others. Just add a check here whether the status is a number or not and decode as necessary
        ? feature.graphic.attributes.DEVTYPE
        : ((feature.graphic.layer as __esri.FeatureLayer).getFieldDomain('DEVTYPE') as __esri.CodedValueDomain).getName(
              feature.graphic.attributes.DEVTYPE
          );

    return devType;
};

const getStatusDesc = (statusAttribute: any, layer: __esri.FeatureLayer) => {
    return isNaN(statusAttribute) // this is really strange. WinCo Foods status is decoded at some point but not sure where. Possibly others. Just add a check here whether the status is a number or not and decode as necessary
        ? statusAttribute
        : (layer.getFieldDomain('STATUS') as __esri.CodedValueDomain).getName(statusAttribute);
};

const PGRLayer = () => {
    const title = 'Planned Grocery';
    const baseUrl = 'https://services1.arcgis.com/aUqH6d4IMis39TBB/arcgis/rest/services/BD_FUTURE_RETAIL/FeatureServer/0';

    // Labels
    const nameExpression = '$feature.NAME';
    const statusExpression =
        '"(" + Decode($feature.STATUS, 0, "Built", 1, "Under Construction", 2, "Proposed", 3, "Planned", "") + ")"';
    const opendateExpression = 'Text($feature.OPENDATE, "Y-MMM-D")';
    const openExpression = `IIf($feature.OPENDATE > 0, ${opendateExpression}, $feature.OPENDATEAPPROX)`;

    const textSymbol = (CATextSymbol()).clone();
    textSymbol.color = new Color('#429c3e');
    textSymbol.horizontalAlignment = 'center';
    
    const labelClass = new LabelClass({
        symbol: textSymbol,
        labelPlacement: 'center-right',
        minScale: 1000000,
        labelExpressionInfo: {
            expression: `${nameExpression} + TextFormatting.NewLine + ${statusExpression} + TextFormatting.NewLine + ${openExpression}`
        }
    });

    // Basic point renderer
    const pointRenderer = new SimpleRenderer({
        symbol: new SimpleMarkerSymbol({
            style: "circle",
            color: new Color("#429c3e"),
            size: 8,
            outline: {
                color: new Color("white"),
                width: 1
            }
        })
    });

    const popupTemplate = new PopupTemplate({
        outFields,
        lastEditInfoEnabled: false,
        title: (feature: __esri.Feature) => {
            const storeName = document.createElement('span');
            storeName.innerHTML = feature.graphic.attributes.NAME;

            const status = getStatusDesc(
                feature.graphic.attributes.STATUS,
                feature.graphic.layer as __esri.FeatureLayer
            );

            return `${storeName.outerHTML} (${status.replace('Under ', '')})`;
        },
        content: (feature: __esri.Feature) => {
            try {
            const node = document.createElement('div');
            const root = ReactDOM.createRoot(node);

            const logoClasses = classNames({
                'text-danger':
                    feature.graphic.attributes.STATUS === 99 || feature.graphic.attributes.STATUS === 'Dead Deal',
                'd-flex align-items-center': true,
                'mb-1': true
            });

            const point = feature.graphic.geometry as Point;

            const popupConent = (
                <React.Fragment>
                    <Row className="text-muted small mx-0">
                        <Col className="px-0" xs="4">
                            Est. Open Date
                        </Col>
                        <Col className="px-0 text-center" xs="3">
                            Dev Type
                        </Col>
                        <Col className="px-0 text-right" xs="5">
                            Size
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col className="px-0" xs="4">
                            {getOpenDate(feature.graphic.attributes.OPENDATE) ||
                                feature.graphic.attributes.OPENDATEAPPROX ||
                                'N/A'}
                        </Col>
                        <Col className="px-0 text-center" xs="3">
                            {getDevType(feature)}
                        </Col>
                        <Col className="text-right px-0" xs="5">
                            {getSize(feature.graphic.attributes.SIZESF, feature.graphic.attributes.SIZESFTYPE)}
                        </Col>
                    </Row>
                    <div className="mt-2">
                        <div className={logoClasses}>
                            <span>{feature.graphic.attributes.NAME}</span>
                            <span className="ms-1">
                                (
                                {getStatusDesc(
                                    feature.graphic.attributes.STATUS,
                                    feature.graphic.layer as __esri.FeatureLayer
                                )}
                                )
                            </span>
                        </div>
                        {feature.graphic.attributes.DESCLOCATION}
                        <br />
                        {feature.graphic.attributes.CITY}, {feature.graphic.attributes.STATE}{' '}
                        {feature.graphic.attributes.ZIP}
                    </div>
                    <div className={feature.graphic.attributes.NOTES ? 'mt-2' : 'd-none'}>
                        <div className="text-muted small">Notes</div>
                        {feature.graphic.attributes.NOTES}
                    </div>
                    <div className="mt-2">
                        {getDevLink(feature.graphic.attributes.DEVELOPER, feature.graphic.attributes.PROJECTURL)}
                    </div>
                    <div className="mt-2 text-right">
                        {feature.graphic.attributes.SOURCE ? (
                            <Button
                                className="p-0"
                                color="link"
                                title="Source"
                                onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                evt.preventDefault();
                                window.open(feature.graphic.attributes.SOURCE, '_blank', 'noopener');
                            }}
                        >
                            <FontAwesomeIcon icon={faNewspaper} className="me-1 pgr-popup-link-icon" />
                                <span className="d-none d-md-inline">Source</span>
                            </Button>
                        ) : null}
                    </div>
                </React.Fragment>
            );

            root.render(popupConent);

            return node;

        } catch (error) {
            debugger;
            console.error(error);
        }
        }
    });

    // Create sublayers for each status
    const statusLayers = [
        { value: 0, title: 'Built', visible: true },
        { value: 1, title: 'Under Construction', visible: true },
        { value: 2, title: 'Proposed', visible: true },
        { value: 3, title: 'Planned', visible: true },
        { value: 99, title: 'Dead Deal', visible: false }
    ].map(status => {
        return new FeatureLayer({
            url: baseUrl,
            title: status.title,
            id: getCALayerId(`${title} - ${status.title}`),
            visible: status.visible,
            definitionExpression: `STATUS = ${status.value}`,
            labelingInfo: [labelClass],
            renderer: pointRenderer,
            popupTemplate
        });
    });

    return new GroupLayer({
        title,
        id: getCALayerId(title),
        visible: false,
        visibilityMode: 'independent',
        layers: statusLayers
    });
};

export default PGRLayer;
