import Search from '@arcgis/core/widgets/Search';
import LocatorSearchSource from '@arcgis/core/widgets/Search/LocatorSearchSource';
import Expand from '@arcgis/core/widgets/Expand';
import type MapView from '@arcgis/core/views/MapView';
import type SceneView from '@arcgis/core/views/SceneView';

const CASearchWidget = (view: MapView | SceneView) => {

    const search = new Search({
        view,
        includeDefaultSources: false,
        allPlaceholder: 'Search'
    });

    const geocoderSource = new LocatorSearchSource({
        countryCode: 'US',
        name: 'Search Addresses',
        placeholder: 'Search Addresses',
        url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
    });

    search.sources.add(geocoderSource);

    return new Expand({
        content: search,
        view
    });
};

export default CASearchWidget;
