import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import CAInfoLayer from './ca/CAInfoLayer';
import CASortLayers from '../helpers/CASortLayers';
import CACBSALayer from './supporting/CACBSALayer';
import CACountyLayer from './supporting/CACountyLayer';
import CAZipCodeLayer from './supporting/CAZipCodeLayer';
import CACrimeLayer from './supporting/CACrimeLayer';
import CAFTrafficLayer from './supporting/CATrafficLayer';
import CXBigBoxLayer from './cxy/CXBigBoxLayer';
import CXChainGroceryLayer from './cxy/CXYChainGroceryLayer';
import CASmallGroceryLayer from './ca/CASmallGroceryLayer';
import CAClosedGroceryLayer from './ca/CAClosedGroceryLayer';
import CXYClubStoreLayer from './cxy/CXYClubStoreLayer';
import CXYPharmacyLayer from './cxy/CXYPharmacyLayer';
import CXYDollarStoreLayer from './cxy/CXYDollarStoreLayer';
import CXYDepartmentStoreLayer from './cxy/CXYDepartmentStoreLayer';
import CXYBankLayer from './cxy/CXYBankLayer';
import CXYFitnessLayer from './cxy/CXYFitnessLayer';
import CXYTheaterLayer from './cxy/CXYTheaterLayer';
import CXYHomeImprovementLayer from './cxy/CXYHomeImprovementLayer';
import CXYOtherLayer from './cxy/CXYOtherLayer';
import CABufferLayer from './supporting/CABufferLayer';
import CAParcelsLayer from './supporting/CAParcelsLayer';
import PGRLayer from './supporting/PGRLayer';
import CAMHIRendererWidget from '../widgets/CAMHIRendererWidget';
import CADemographicLayers from './supporting/CADemographicsLayer';

export const initializeLayers = async (view: MapView, map: Map): Promise<__esri.Layer[]> => {
    // We need to get a reference to this layer to pass to the mhi renderer widget
    const demographicsLayer = CADemographicLayers(view);
    const mhiWidget = CAMHIRendererWidget(view, demographicsLayer);
    view.ui.add(mhiWidget, {position: 'top-left', index: 2});

    const layers: __esri.Layer[] = [
        CAInfoLayer(),
        CASmallGroceryLayer(),
        CAClosedGroceryLayer(),
        CXBigBoxLayer(),
        CXYPharmacyLayer(),
        CXYClubStoreLayer(),
        CXChainGroceryLayer(),
        CXYBankLayer(),
        CXYDollarStoreLayer(),
        CXYDepartmentStoreLayer(),
        CXYFitnessLayer(),
        CXYHomeImprovementLayer(),
        CXYTheaterLayer(),
        CXYOtherLayer(),
        CACBSALayer(),
        CACountyLayer(),
        CACrimeLayer(),
        demographicsLayer,
        CAFTrafficLayer(),
        CAZipCodeLayer(),
        CABufferLayer(),
        CAParcelsLayer()
    ];

    // [
    //     await CAParcelLayer(view),
    // ].forEach(l => {
    //     if (l) {
    //         layers.push(l);
    //     }
    // });

    // const resolvedLayers = await Promise.all(layerPromises);
    // const layers = resolvedLayers.filter(l => l) as __esri.Layer[];

    layers.sort(CASortLayers);
    map.addMany(layers);
    map.add(PGRLayer()); // add PGRLayer last so it's on top of all other layers

    return layers;
};
