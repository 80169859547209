import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { getCALayerId } from '../../helpers/CALayerID';
import CXYPopup from '../../popups/CXYPopup';
import { CXYLayerLabels } from '../../helpers/CALayerLabels';

const CXYHomeImprovementLayer = () => {
    const title = 'Home Improvement';

    return new FeatureLayer({
        title,
        id: getCALayerId(title),
        visible: false,
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/Collection_296589/FeatureServer/0',
        labelingInfo: [CXYLayerLabels()],
        popupTemplate: CXYPopup(),
        legendEnabled: false
    });
};

export default CXYHomeImprovementLayer;
