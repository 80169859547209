import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';

const Busy: React.FC<{ view: __esri.MapView | __esri.SceneView }> = props => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handle = reactiveUtils.watch(
            () => props.view.updating,
            (updating) => setLoading(updating)
        );
        
        return () => {
            handle.remove();
        };
    }, [props.view]);

    if (!loading) {
        return <></>;
    }
    return (

        <div className={loading ? 'esri-widget--button esri-widget esri-interactive' : 'd-none'} title="Loading">
            <span className="fa fa-spinner fa-spin" />
        </div>
    );
};

const CALoadingWidget = (view: __esri.MapView | __esri.SceneView) => {
    const node = document.createElement('div');
    const root = ReactDOM.createRoot(node);
    root.render(<Busy view={view} />);
    return node;
};

export default CALoadingWidget;
