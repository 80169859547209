import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { getCALayerId } from '../../helpers/CALayerID';
import CXYPopup from '../../popups/CXYPopup';
import { CXYLayerLabels } from '../../helpers/CALayerLabels';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';

const CXYPharmacyLayer = () => {
    const title = 'Pharmacies';

    // const renderer = new UniqueValueRenderer({
    //     field: "ChainName",
    //     defaultSymbol: new SimpleMarkerSymbol({
    //         style: 'circle',
    //         size: 8,
    //         color: [50, 205, 50, 255],
    //         outline: {
    //             color: [0, 0, 0, 255],
    //             width: 1
    //         }
    //     }),
    //     uniqueValueInfos: [
    //         {
    //             value: "CVS",
    //             symbol: new PictureMarkerSymbol({
    //                 url: "logos/cvs.png",
    //                 width: "24px",
    //                 height: "24px"
    //             })
    //         },
    //         {
    //             value: "Rite Aid",
    //             symbol: new PictureMarkerSymbol({
    //                 url: "logos/riteaid.png",
    //                 width: "24px",
    //                 height: "24px"
    //             })
    //         },
    //         {
    //             value: "Walgreens",
    //             symbol: new PictureMarkerSymbol({
    //                 url: "logos/walgreens.png",
    //                 width: "24px",
    //                 height: "24px"
    //             })
    //         }
    //     ]
    // });

    return new FeatureLayer({
        title,
        id: getCALayerId(title),
        visible: false,
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/Collection_296193/FeatureServer/0',
        labelingInfo: [CXYLayerLabels()],
        popupTemplate: CXYPopup(),
        legendEnabled: false,
        // renderer: renderer
    });
};

export default CXYPharmacyLayer;
