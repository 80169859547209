import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Button, Label, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { mhiCABreakRenderer, mhiCALayerTitle } from '../layers/supporting/CADemographicsLayer';
import format from 'number-format.js';
import Expand from '@arcgis/core/widgets/Expand';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import Query from '@arcgis/core/rest/support/Query';
import { executeQueryJSON } from '@arcgis/core/rest/query';
import getClassBreaks from '@arcgis/core/smartMapping/statistics/classBreaks';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

const mhiFeild = 'MEDHINC_CY';
const dollarNumberFormat = '$#,###.';

interface ICAMHIRendererProps {
    reset: () => boolean;
    setExtentRenderer: () => Promise<boolean>;
}

const CAMHIRenderer: React.FC<ICAMHIRendererProps> = props => {
    const [updating, setUpdating] = useState(false);

    const handleUpdateClick = async () => {
        setUpdating(true);
        const success = await props.setExtentRenderer();
        setUpdating(!success);
    };

    return (
        <div className="esri-widget esri-layer-list esri-widget--panel">
            <div className="d-flex justify-content-between">
                <Label>Symbolize MHI</Label>
                <FontAwesomeIcon className={updating ? 'd-block' : 'd-none'} icon={faSpinner} spin={updating} />
            </div>
            <ButtonGroup>
                <Button color="primary" onClick={handleUpdateClick}>
                    Update
                </Button>
                <Button
                    color="info"
                    onClick={() => {
                        setUpdating(true);
                        setUpdating(!props.reset());
                    }}
                >
                    Reset
                </Button>
            </ButtonGroup>
        </div>
    );
};

const CAMHIRendererWidget = (view: __esri.MapView | __esri.SceneView, demographicsLayer: __esri.GroupLayer) => {

    const mhiField = 'MEDHINC_CY';
    const defaultRender = ClassBreaksRenderer.fromJSON(mhiCABreakRenderer);

    const node = document.createElement('div');

    const container = document.createElement('div');
    container.classList.add('d-none');

    const toggleContainer = (mhiVisible: boolean, demoVisible: boolean) => {
        if (mhiVisible && demoVisible) {
            container.classList.remove('d-none');
        } else {
            container.classList.add('d-none');
        }
    };

    const caMHILayers = demographicsLayer.layers.find(l => l.title === mhiCALayerTitle) as __esri.GroupLayer;

    demographicsLayer.watch('visible', () => {
        toggleContainer(caMHILayers.visible, demographicsLayer.visible);
    });

    caMHILayers.watch('visible', () => {
        toggleContainer(caMHILayers.visible, demographicsLayer.visible);
    });

    const reset = () => {
        caMHILayers.layers.forEach(l => {
            (l as __esri.FeatureLayer).renderer = defaultRender.clone();
        });
        return true;
    };

    const getVisibleLayer = () => {
        return caMHILayers.layers.find(
            fLayer =>
                view.scale <= (fLayer as __esri.FeatureLayer).minScale &&
                view.scale >= (fLayer as __esri.FeatureLayer).maxScale
        );
    };

    const getGetFeatureSet = async(layer: __esri.FeatureLayer) => {
        const query = new Query();
        query.returnGeometry = true;
        query.outFields = ['OBJECTID', mhiField];
        query.geometry = view.extent;
        query.spatialRelationship = 'envelope-intersects';
        return await executeQueryJSON(layer.url + '/' + layer.layerId, query);
    };

    const getClassBreaksValues = async (featureSet: __esri.FeatureSet, visibleLayer: __esri.FeatureLayer) => {
        const objectIds = featureSet.features.map(f => {
            return f.attributes.OBJECTID;
        });

        const fLayer = new FeatureLayer({
            url: `${visibleLayer.url}/${visibleLayer.layerId}`,
            definitionExpression: `OBJECTID IN (${objectIds.join(', ')})`,
            outFields: ['OBJECTID', mhiField],
            visible: false
        });

        return await getClassBreaks({
            layer: fLayer,
            field: mhiFeild,
            classificationMethod: 'equal-interval',
            numClasses: 5
        });
    };

    const setRenderer = (breaks: __esri.ClassBreaksResult) => {
        const renderer = new ClassBreaksRenderer({
            field: mhiFeild
        });

        breaks.classBreakInfos.forEach((i, idx) => {
            const symbol = (defaultRender.classBreakInfos[idx].symbol as __esri.SimpleFillSymbol).clone();
            renderer.addClassBreakInfo(i.minValue, i.maxValue, symbol);
            renderer.classBreakInfos[idx].label = `${format(dollarNumberFormat, i.minValue)} - ${format(
                dollarNumberFormat,
                i.maxValue
            )}`;

            if (idx === breaks.classBreakInfos.length - 1) {
                renderer.classBreakInfos[idx].label = `> ${format(dollarNumberFormat, i.minValue)}`;
            }
        });

        caMHILayers.layers.forEach(l => {
            (l as __esri.FeatureLayer).renderer = renderer;
        });
    };

    const setExtentRenderer = async () => {
        const visibleLayer = getVisibleLayer() as __esri.FeatureLayer;
        const featureSet = await getGetFeatureSet(visibleLayer);
        const classBreaks = await getClassBreaksValues(featureSet, visibleLayer);
        setRenderer(classBreaks);
        return true;
    };

    const controls = <CAMHIRenderer reset={reset} setExtentRenderer={setExtentRenderer} />;

    const root = ReactDOM.createRoot(node);
    root.render(controls);

    return new Expand({
        container,
        view,
        content: node,
        expandTooltip: 'CA MHI Renderer Settings',
        expandIcon: 'collection'
    });
};

export default CAMHIRendererWidget;
