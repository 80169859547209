import { CA_LAYER_ID_PREFIX } from '../helpers/CAConstants';
import LayerList from '@arcgis/core/widgets/LayerList';
import Expand from '@arcgis/core/widgets/Expand';

const CALayerListCAWidget = (view: __esri.MapView | __esri.SceneView) => {

    const layerList = new LayerList({
        view,
        listItemCreatedFunction: i => {
            const item = i.item as __esri.ListItem;

            const actionsSections = [
                [
                    {
                        title: 'Go to full extent',
                        className: 'esri-icon-zoom-out-fixed',
                        id: 'full-extent'
                    }
                ],
                [
                    {
                        title: 'Increase opacity',
                        className: 'esri-icon-up',
                        id: 'increase-opacity'
                    },
                    {
                        title: 'Decrease opacity',
                        className: 'esri-icon-down',
                        id: 'decrease-opacity'
                    }
                ]
            ] as any;

            if (item.layer.id.startsWith(CA_LAYER_ID_PREFIX)) {
                item.actionsSections = [
                    ...actionsSections,
                    [
                        {
                            title: `Toggle Labels ${(item.layer as __esri.FeatureLayer).labelsVisible ? 'off' : 'on'}`,
                            className: 'esri-icon-comment',
                            id: 'toggle-labels'
                        },
                        {
                            title: 'Toggle Label Position',
                            className: 'esri-icon-environment-settings',
                            id: 'toggle-label-position'
                        }
                    ]
                ] as any;
            } else {
                item.actionsSections = actionsSections;
            }
        }
    });

    const setLabelPosition = (layer: __esri.FeatureLayer) => {
        if (layer.labelingInfo) {
            const positions = [
                'above-center',
                'above-right',
                'center-right',
                'below-right',
                'below-center',
                'below-left',
                'center-left',
                'above-left',
                'center-center'
            ];

            const currentIndex = positions.indexOf(layer.labelingInfo[0].labelPlacement);
            let nextIndex = currentIndex + 1;

            if (currentIndex === positions.length - 1) {
                nextIndex = 0;
            }

            const newLabelInfo = layer.labelingInfo[0].clone();

            newLabelInfo.labelPlacement = positions[nextIndex] as any;
            layer.labelingInfo = [newLabelInfo];
        }
    };

    layerList.on('trigger-action', evt => {
        if (evt.action.id === 'full-extent') {
            view.goTo(evt.item.layer.fullExtent);
        } else if (evt.action.id === 'increase-opacity') {
            if (evt.item.layer.opacity < 1) {
                evt.item.layer.opacity += 0.125;
            }
        } else if (evt.action.id === 'decrease-opacity') {
            if (evt.item.layer.opacity > 0) {
                evt.item.layer.opacity -= 0.125;
            }
        } else if (evt.action.id === 'toggle-labels') {
            (evt.item.layer as __esri.FeatureLayer).labelsVisible = !(evt.item.layer as __esri.FeatureLayer)
                .labelsVisible;
            evt.action.title = `Toggle Labels ${(evt.item.layer as __esri.FeatureLayer).labelsVisible ? 'off' : 'on'}`;
        } else if (evt.action.id === 'toggle-label-position') {
            setLabelPosition(evt.item.layer as __esri.FeatureLayer);
        }
    });

    layerList.operationalItems.watch('length', () => {
        const layers = layerList.operationalItems.filter(i => i.layer.id.startsWith(CA_LAYER_ID_PREFIX));
        layerList.operationalItems.removeAll();
        layerList.operationalItems.addMany(layers);
    });

    return new Expand({
        view,
        content: layerList,
        expandTooltip: 'CA Layers',
        expandIcon: 'pin-tear'
    });
};

export default CALayerListCAWidget;
