import ActionButton from '@arcgis/core/support/actions/ActionButton';

export const CAViewOnGooglePopupAction = () => {
    return new ActionButton({
        title: 'View in Google',
        id: 'view-in-google',
        className: 'fab fa-google'
    });
}

export const CANearbyStatsPopupAction = () => {
    return new ActionButton({
        title: 'Nearby Stats',
        id: 'nearby-stats',
        className: 'esri-icon-chart'
    });
}
