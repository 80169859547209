import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import { getSupportingLayerId } from '../../helpers/CALayerID';

const CAZipCodeLayer = () => {
    const title = 'Zip Code';

    return new VectorTileLayer({
        url: 'https://tiles.arcgis.com/tiles/osXWqrTKrwWBat15/arcgis/rest/services/ZIP_Codes/VectorTileServer',
        title,
        id: getSupportingLayerId(title),
        visible: false
    });
};

export default CAZipCodeLayer;
