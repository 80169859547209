import React, { useEffect, useRef } from 'react';
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import CAOpenPointInGoogle from './helpers/CAOpenPointInGoogle';
import { CARingBuffer } from './helpers/CABufferHelpers';
import { CA_DEFAULT_BUFFER_VALUES } from './helpers/CAConstants';
import { initializeLayers } from './layers/CALayers';
import { initializeWidgets } from './widgets/CAWidgets';
import LOD from "@arcgis/core/layers/support/LOD";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import Portal from "@arcgis/core/portal/Portal.js";
import esriId from '@arcgis/core/identity/IdentityManager'

const CAMap: React.FC = () => {
    const mapDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!mapDiv.current) return;

        // Get URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const zoom = parseInt(urlParams.get('z') || '7');
        const lat = parseFloat(urlParams.get('lat') || '40.433317288935974');
        const lon = parseFloat(urlParams.get('lon') || '-74.23236161041743');

        const map = new Map({
            basemap: "streets-navigation-vector"
        });

        const lods = [
            { level: 0, resolution: 156543.03392800014, scale: 591657527.591555 },
            { level: 1, resolution: 78271.51696399994, scale: 295828763.795777 },
            { level: 2, resolution: 39135.75848200009, scale: 147914381.897889 },
            { level: 3, resolution: 19567.87924099992, scale: 73957190.948944 },
            { level: 4, resolution: 9783.93962049996, scale: 36978595.474472 },
            { level: 5, resolution: 4891.96981024998, scale: 18489297.737236 },
            { level: 6, resolution: 2445.98490512499, scale: 9244648.868618 },
            { level: 7, resolution: 1222.992452562495, scale: 4622324.434309 },
            { level: 8, resolution: 611.4962262813797, scale: 2311162.217155 },
            { level: 9, resolution: 305.74811314055756, scale: 1155581.108577 },
            { level: 10, resolution: 152.87405657041106, scale: 577790.554289 },
            { level: 11, resolution: 76.43702828507324, scale: 288895.277144 },
            { level: 12, resolution: 38.21851414253662, scale: 144447.638572 },
            { level: 13, resolution: 19.10925707126831, scale: 72223.819286 },
            { level: 14, resolution: 9.554628535634155, scale: 36111.909643 },
            { level: 15, resolution: 4.77731426794937, scale: 18055.954822 },
            { level: 16, resolution: 2.388657133974685, scale: 9027.977411 },
            { level: 17, resolution: 1.1943285668550503, scale: 4513.988705 },
            { level: 18, resolution: 0.5971642835598172, scale: 2256.994353 },
            { level: 19, resolution: 0.29858214164761665, scale: 1128.497176 },
            { level: 20, resolution: 0.14929107082380833, scale: 564.248588 },
            { level: 21, resolution: 0.07464553541190416, scale: 282.124294 },
            { level: 22, resolution: 0.03732276770595208, scale: 141.062147 },
            { level: 23, resolution: 0.01866138385297604, scale: 70.5310735 }
        ].map(lod => new LOD(lod));

        const view = new MapView({
            container: mapDiv.current,
            map: map,
            center: [lon, lat],
            zoom: zoom,
            popup: {
                dockEnabled: true,
                dockOptions: {
                    position: "top-right",
                    breakpoint: false
                }
            },
            constraints: {
                rotationEnabled: false,
                lods
            }
        });
        
        view.when(() => {
            // Debounced function to update URL
            let updateTimeout: NodeJS.Timeout;
            const updateURL = () => {
                clearTimeout(updateTimeout);
                updateTimeout = setTimeout(() => {
                    const center = view.center;
                    const zoom = Math.round(view.zoom);
                    const newURL = new URL(window.location.href);
                    newURL.searchParams.set('z', zoom.toString());
                    newURL.searchParams.set('lat', center.latitude.toFixed(6));
                    newURL.searchParams.set('lon', center.longitude.toFixed(6));
                    window.history.replaceState({}, '', newURL.toString());
                }, 300); // Debounce for 300ms
            };

            // Watch for center and zoom changes
            view.watch('center', updateURL);
            view.watch('zoom', updateURL);

            // Setup popup behavior
            view.popup.dockEnabled = true;
            
            reactiveUtils.on(
                () => view.popup?.viewModel,
                "trigger-action",
                (evt) => {
                    const id = evt.action.id;
                    const geometry = view.popup.selectedFeature?.geometry;

                    if (!geometry) return;

                    switch (id) {
                        case 'view-in-google':
                            CAOpenPointInGoogle(geometry);
                            break;
                        case 'nearby-stats':
                            if (geometry.type === 'point') {
                                CARingBuffer(geometry as Point, CA_DEFAULT_BUFFER_VALUES, view);
                            } else if (geometry.type === 'polygon') {
                                const polygon = geometry as Polygon;
                                const centroid = polygon.centroid;
                                if (centroid) {
                                    CARingBuffer(centroid, CA_DEFAULT_BUFFER_VALUES, view);
                                }
                            }
                            break;
                    }
                }
            );

            // Try to load stored credentials
            const storedCredential = window.localStorage.getItem('ca-app:identity-manager');
            if (storedCredential) {
                const credential = JSON.parse(storedCredential);
                // Check if credential is expired
                const now = Date.now();
                if (credential && credential.credentials?.[0]?.expires > now) {
                    esriId.initialize(credential);
                } else {
                    // Clear expired credential
                    window.localStorage.removeItem('ca-app:identity-manager');
                }
            }

            const portal = new Portal();
            portal.authMode = "immediate";

            portal.load().then(() => {
                const credentials = esriId.toJSON().credentials;
                if (credentials && credentials.length > 0) {
                    const creds = esriId.toJSON();
                    window.localStorage.setItem('ca-app:identity-manager', JSON.stringify(creds));
                }
                
                console.log("Portal loaded");

                initializeLayers(view, map);
                initializeWidgets(view);
            }).catch((error) => {
                console.error("Error loading portal", error);
            });
        });

        return () => {
            view.destroy();
        };
    }, []);

    return (
        <div ref={mapDiv} className="mapContainer" />
    );
};

export default CAMap;
