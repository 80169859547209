import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useInputValue } from '../helpers/CAHooks';
import { Input, Button, ButtonGroup, InputGroup, FormGroup } from 'reactstrap';
import classNames from 'classnames';
import Switch from 'react-switch';
import { FixedSizeList as List } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { chainGroceryTitle } from '../layers/cxy/CXYChainGroceryLayer';
import Expand from '@arcgis/core/widgets/Expand';
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";


interface ICAGroupLayerListFilterWidgetItem {
    label: string;
    logo: HTMLElement;
    visible: boolean;
    layer: __esri.FeatureLayer;
}

const sortItems = (a: ICAGroupLayerListFilterWidgetItem, b: ICAGroupLayerListFilterWidgetItem) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
    }

    if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
    }

    return 0;
};

const getLogoHTML = async (symbol: __esri.Symbol) => {
    let logo: HTMLElement;

    if (symbol.type === 'picture-marker') {
        const img = document.createElement('img');
        img.style.maxWidth = '42px';
        img.style.minHeight= '10px';
        img.style.maxHeight= '24px';

        img.src = (symbol as __esri.PictureMarkerSymbol).url;
        logo = img;
    } else {
        let size = 8;
        if (symbol.type === 'simple-marker') {
            size = (symbol as __esri.SimpleMarkerSymbol).size;
        }

        logo = await symbolUtils.renderPreviewHTML(symbol, { size });
    }

    return logo;
};

const CAGroupLayerListByFilterWidgetContent: React.FC<{ layer: __esri.FeatureLayer }> = props => {
    const searchText = useInputValue('');
    const [items, setItems] = useState<ICAGroupLayerListFilterWidgetItem[]>([]);
    const [labelsVisible, setLabelsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState('1<>1');

    useEffect(() => {
        if (query !== props.layer.definitionExpression) {
            props.layer.definitionExpression = query;
        }
    }, [query, props.layer]);

    useEffect(() => {
        const init = () => {
            const storeItems: ICAGroupLayerListFilterWidgetItem[] = [];
            const promises: any = [];

            if (props.layer.renderer.type === 'unique-value') {
                const renderer = props.layer.renderer as __esri.UniqueValueRenderer;

                renderer.uniqueValueInfos.forEach(i => {
                    const promise = new Promise(async (resolve, reject) => {
                        // we have to keep track of the promises in order to know when to render the list so just create one and push to an array then resolve when the logo await is done
                        try {
                            const logo = await getLogoHTML(i.symbol);
                            const item: ICAGroupLayerListFilterWidgetItem = {
                                label: i.label,
                                logo,
                                visible: false,
                                layer: props.layer
                            };

                            storeItems.push(item);
                            resolve(void 0);
                        } catch (error) {
                            reject(error);
                        }
                    });

                    promises.push(promise);
                });
                Promise.all(promises).then(p => {
                    setItems([...storeItems.sort(sortItems)]);
                });
                setIsLoading(false);
            }
        };

        init();
    }, [props.layer]);

    useEffect(() => {
        const visibleLabels = items.filter(i => i.visible);
        if (visibleLabels.length) {
            const newQuery = `ChainName in (${visibleLabels.map(i => `'${i.label.replace("'", "''")}'`).join(', ')})`;
            setQuery(newQuery);
        } else {
            setQuery('1<>1');
        }
    }, [items]);

    const filterItems = (i: ICAGroupLayerListFilterWidgetItem) => {
        return searchText.value ? i.label.toLowerCase().indexOf(searchText.value.toLowerCase()) >= 0 : true;
    };

    const toggleAll = (visible: boolean) => {
        items.filter(filterItems).forEach(i => (i.visible = visible));
        setItems([...items]);
    };

    const toggleLabels = () => {
        props.layer.labelsVisible = !props.layer.labelsVisible;
        setLabelsVisible(!labelsVisible);
    };

    const toggleLabel = (item: ICAGroupLayerListFilterWidgetItem) => {
        let idx = items.findIndex(i => i.label === item.label);
        item.visible = !item.visible;
        if (idx) {
            items[idx] = item;
        }
        setItems([...items]);
    };

    const loadingDiv = <h6 className="my-5 text-center">Loading...</h6>;

    const ItemRow = (rowProps: { index: number; style: any }) => {
        const item = items.filter(filterItems)[rowProps.index];
        return (
            <div className="mb-1" style={{ ...rowProps.style, 
                borderBottom: '1px solid rgba(110,110,110,0.3)'
             }}>
                <div className="" style={{padding: '10px'}}>
                    <div
                        className="d-flex align-items-center"
                        onClick={() => {
                            toggleLabel(item);
                        }}
                    >
                        <span className="d-flex align-items-center">
                            <span
                                className={classNames('', {
                                    'esri-icon-visible': item.visible,
                                    'esri-icon-non-visible': !item.visible
                                })}
                            />
                        </span>
                        <span
                            className="d-flex align-items-center ps-3"
                            title={item.label}
                            aria-label={item.label}
                        >
                            <span dangerouslySetInnerHTML={{ __html: item.logo.outerHTML }}></span>
                            <span className="ms-1">{item.label}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const controls = (
        <React.Fragment>
            <FormGroup className="mb-2">
                <InputGroup size="sm">
                    <Input
                        placeholder={`Search ${props.layer.title}`}
                        type="text"
                        value={searchText.value}
                        onChange={searchText.onChange}
                    />
                    <Button
                        aria-label="Clear Search Text"
                        title="Clear Search Text"
                        disabled={searchText.value.length === 0}
                        onClick={() => {
                            searchText.setValue('');
                        }}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                </InputGroup>
            </FormGroup>
            <div className="d-flex align-items-center align-items-center justify-content-between">
                <label className="d-flex align-items-center align-items-center mb-0 me-2">
                    Labels
                    <Switch height={21} width={42} className="ms-1" checked={labelsVisible} onChange={toggleLabels} />
                </label>
                <ButtonGroup className="my-1" size="sm">
                    <Button
                        color="success"
                        onClick={() => {
                            toggleAll(true);
                        }}
                    >
                        All On
                    </Button>
                    <Button
                        color="warning"
                        onClick={() => {
                            toggleAll(false);
                        }}
                    >
                        All Off
                    </Button>
                </ButtonGroup>
            </div>
            <List
                itemSize={40}
                itemCount={items.filter(filterItems).length}
                height={540}
                width="auto"
                className=""
            >
                {ItemRow}
            </List>
        </React.Fragment>
    );

    return <div className="esri-layer-list esri-widget esri-widget--panel d-block m-1">{isLoading ? loadingDiv : controls}</div>;
};

const CAChainGroceryWidget = (
    view: __esri.MapView | __esri.SceneView,
    layerTitle: string,
    expandIconClass: string
) => {
    const content = document.createElement('div');
    const root = ReactDOM.createRoot(content);

    const expand = new Expand({
        view,
        expandTooltip: layerTitle,
        expandIcon: expandIconClass,
        content
    });

    reactiveUtils.when(() => expand.expanded, () => {
        const layer = view.map.allLayers.find(l => l.title === layerTitle);
        if (layer) {
            root.render(<CAGroupLayerListByFilterWidgetContent layer={layer as __esri.FeatureLayer} />);
        } else {
            throw new Error('Layer must be a FeatureLayer.');
        }
    });

    return expand;
};

export default CAChainGroceryWidget;
