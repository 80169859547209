import { CAGetBufferGraphic } from './CABufferHelpers';
import { CA_COLORS } from './CAConstants';
import Graphic from '@arcgis/core/Graphic';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import Polyline from '@arcgis/core/geometry/Polyline';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';

export const CADrawPolygon = (
    evt: __esri.PolygonDrawAction,
    view: __esri.MapView | __esri.SceneView,
    complete: boolean,
) => {

    const densifyPolyline = (polyline: __esri.Polyline) => {
        const simplifiedPolyline = geometryEngine.simplify(polyline) as __esri.Polyline;
        return geometryEngine.geodesicDensify(simplifiedPolyline, 100, 'miles') as __esri.Polyline;
    };

    const lineSymbol = new SimpleLineSymbol({
        color: CA_COLORS.Primary,
        width: 2.5
    });

    const polygonSymbol = new SimpleFillSymbol({
        color: CA_COLORS.PrimaryTransparent,
        outline: lineSymbol
    });

    view.graphics.removeAll();
    const graphic = CAGetBufferGraphic();

    if (evt.vertices.length === 2) {
        const polyline = new Polyline({
            paths: [evt.vertices],
            spatialReference: view.spatialReference
        });

        const densifiedPolyline = densifyPolyline(polyline);

        const graphic = new Graphic({
            geometry: densifiedPolyline,
            symbol: lineSymbol
        });

        view.graphics.add(graphic);
    } else if (evt.vertices.length >= 2) {
        const polygon = new Polygon({
            rings: [evt.vertices],
            spatialReference: view.spatialReference
        });
        const densifiedPolygon = geometryEngine.geodesicDensify(polygon, 100, 'miles') as __esri.Polygon;

        graphic.geometry = densifiedPolygon || polygon;
        graphic.symbol = polygonSymbol;
        view.graphics.add(graphic);

        if (complete) {
            view.graphics.removeAll();
            return graphic;
        }
    }
};
