import MapView from "@arcgis/core/views/MapView";
import Editor from "@arcgis/core/widgets/Editor";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

interface EditorWidgets {
    editor: Editor;
    toggleButton: HTMLDivElement;
}

const CASketch = (view: MapView): EditorWidgets | null => {
    const caInfoLayer = view.map.allLayers.find(layer => 
        layer.title === 'CA Info'
    ) as FeatureLayer;

    if (!caInfoLayer) {
        console.error('CA Info layer not found');
        return null;
    }

    const editor = new Editor({
        view: view,
        visible: false,
        layerInfos: [{ 
            layer: caInfoLayer,
            formTemplate: {
                elements: [
                    {
                        type: "group",
                        label: "Basic Information",
                        elements: [
                            { type: "field", fieldName: "NAME", label: "Name" },
                            { type: "field", fieldName: "STORE_NUMB", label: "Store Number" },
                            { type: "field", fieldName: "STREET_ADD", label: "Street Address" },
                            { type: "field", fieldName: "CITY", label: "City" },
                            { type: "field", fieldName: "STATE", label: "State" },
                            { type: "field", fieldName: "ZIP_CODE", label: "ZIP Code" }
                        ]
                    },
                    {
                        type: "group",
                        label: "Sales Information",
                        elements: [
                            { type: "field", fieldName: "SM2014_TOTAL_SELL", label: "SM 2014 Total Sales" },
                            { type: "field", fieldName: "SM2014_TOTAL_WEEK", label: "SM 2014 Total Weekly" },
                            { type: "field", fieldName: "SM_2014_Supplier", label: "SM 2014 Supplier" },
                            { type: "field", fieldName: "Sq_Ft", label: "Square Footage" },
                            { type: "field", fieldName: "Weekly_Volume", label: "Weekly Volume" }
                        ]
                    },
                    {
                        type: "group",
                        label: "Dates",
                        elements: [
                            { type: "field", fieldName: "Date_Opened", label: "Date Opened" },
                            { type: "field", fieldName: "Date_Closed", label: "Date Closed" },
                            { type: "field", fieldName: "Info_Date", label: "Information Date" }
                        ]
                    },
                    {
                        type: "group",
                        label: "Additional Information",
                        elements: [
                            { type: "field", fieldName: "Source", label: "Source" },
                            { type: "field", fieldName: "Casing_URL", label: "Casing URL" },
                            { type: "field", fieldName: "Other_URL", label: "Other URL" }
                        ]
                    }
                ] as any,
                title: "CA Info Editor",
                description: "Edit CA Info features"
            } as any
        }],
        supportingWidgetDefaults: {}
    });

    // Create toggle button
    const toggleButton = document.createElement('div');
    toggleButton.className = 'esri-widget esri-widget--button esri-interactive';
    toggleButton.title = 'Toggle Editor';
    toggleButton.innerHTML = '<span class="esri-icon-edit"></span>';
    toggleButton.onclick = () => {
        editor.visible = !editor.visible;
        toggleButton.classList.toggle('esri-button--highlighted');
        
        // If turning off, cancel any active workflow
        if (!editor.visible && editor.activeWorkflow) {
            editor.cancelWorkflow();
        }
    };

    return {
        editor,
        toggleButton
    };
};

export default CASketch; 
