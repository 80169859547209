import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { getCALayerId } from '../../helpers/CALayerID';
import CXYPopup from '../../popups/CXYPopup';
import { CALayerLabels } from '../../helpers/CALayerLabels';

const CASmallGroceryLayer = () => {
    const title = 'Small Grocery';
    const popupTemplate = CXYPopup();

    return new FeatureLayer({
        title,
        id: getCALayerId(title),
        visible: false,
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CA_CAGrocery/FeatureServer/0',
        labelingInfo: [CALayerLabels()],
        popupTemplate
    });
};

export default CASmallGroceryLayer;
