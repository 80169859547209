import Query from '@arcgis/core/rest/support/Query';
import { executeQueryJSON } from '@arcgis/core/rest/query';
import { CAInfoURL } from '../layers/ca/CAInfoLayer';

const CAInfoQuery = async (graphic: __esri.Graphic) => {
    const query = new Query();
    query.returnGeometry = false;
    query.where = `HashId='${graphic.attributes.HashID || graphic.attributes.HashId}'`;
    query.outFields = ['*'];

    return executeQueryJSON(CAInfoURL, query);
};

export default CAInfoQuery;
