import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import { getSupportingLayerId } from '../../helpers/CALayerID';

const CACountyLayer = () => {

    const title = 'Counties';

    return new VectorTileLayer({
        url: 'https://tiles.arcgis.com/tiles/osXWqrTKrwWBat15/arcgis/rest/services/Counties/VectorTileServer',
        title,
        id: getSupportingLayerId(title),
        visible: false
    });
};

export default CACountyLayer;
