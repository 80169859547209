import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Table, Button, Input } from 'reactstrap';
import format from 'number-format.js';
import { customBufferValueName, CACustomReport, CARingReport, CADriveReport } from '../helpers/CABufferHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useInputValue } from '../helpers/CAHooks';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import Point from '@arcgis/core/geometry/Point';

interface ICABufferDataDict {
    [key: string]: string;
}

const dollarFields = [
    'MEDHINC_CY',
    'AVGHINC_CY',
    'MEDVAL_CY',
    'X1003_X',
    'RTTRDSALES',
    'RETTRDPOT',
    'RSALES4451',
    'RETPOT4451',
    'X1003_A',
    'IND4451_X',
    'IND4451_A',
];

export const dataDict: ICABufferDataDict = {
    TOTPOP: 'CY Population',
    // TOTPOP_CY: 'CY Population',
    DPOP_CY: 'CY Daytime Population',
    TOTHH: 'CY Households',
    MEDHINC_CY: 'CY Median HHI',
    AVGHINC_CY: 'CY Average HHI',
    OWNER_CY: 'CY Owner Occupied',
    RENTER_CY: 'CY Renter Occupied',
    VACANT_CY: 'CY Vacant HUs',
    MEDVAL_CY: 'CY Median Home Value',
    TOTPOP_FY: 'FY Population',
    POPGRW1020: '2010-2020 Population Growth',
    POPGRWCYFY: 'CY-FY Population Growth',
    TSPOP10_CY: '2010 Population',
    TSPOP20_CY: '2020 Population',
    MEDAGE_CY: 'CY Median Age',
    ASIAN_CY: 'CY Asian %',
    BLACK_CY: 'CY Black %',
    HISPPOP_CY: 'CY Hispanic %',
    WHITE_CY: 'CY White %',
    AMERIND_CY: 'CY Amer Native',
    RACE2UP_CY: 'CY 2 or more Race',
    PACIFIC_CY: 'CY Pacific Islander',
    OTHRACE_CY: 'CY Other Race',
    DIVINDX_CY: 'CY Diversity Index',

    ASSCDEG_CY: 'CY Assoc. Degree %',
    BACHDEG_CY: 'CY Bach. Degree %',
    GRADDEG_CY: 'CY Grad/Prof Degree %',
    // EDUCBASECY: 'CY Education Base',
    HINC0_CY: 'CY HHI < $15,000',
    HINC15_CY: 'CY HHI $15,000-$24,999',
    HINC25_CY: 'CY HHI $25,000-$34,999',
    HINC35_CY: 'CY HHI $35,000-$49,999',
    HINC50_CY: 'CY HHI $50,000-$74,999',
    HINC75_CY: 'CY HHI $75,000-$99,999',
    HINC100_CY: 'CY HHI $100,000-$149,999',
    HINC150_CY: 'CY HHI $150,000-$199,999',
    HINC200_CY: 'CY HHI >= $200,000',
    X1003_X: 'CY Food at Home $',
    X1003_A: 'CY Food at Home: Avg $',
    X1003_I: 'CY Food at Home: Index',
    N08_SALES: '2024 Retail Trade Sales',
    // RETTRDPOT: '2022 Retail Trade Sales Potential', // doesnt seem to be available anymore
    // LSFRETTRD: '2022 Lkg/Surplus Fctr: Retail Trade',
    // RSALES4451: '2022 Retail Sales: Grocery',
    // RETPOT4451: '2022 Ret Sales Potential: Grocery',
    // LSF4451: '2022 Lkg/Surplus Fctr: Grocery'
    IND4451_X: 'CY Grocery Stores $',
    IND4451_A: 'CY Grocery Stores: Avg $',
    IND4451_I: 'CY Grocery Stores: Index',
    TSEGNAME: 'CY Dominant Tapestry Segment',
    CRMCYTOTC: 'CY Total Crime Index',
    CRMCYPERC: 'CY Personal Crime Index',
    CRMCYPROC: 'CY Property Crime Index',
    ACSSNAP: '2022 HH Receiving SNAP',

    
};

const rawFields : string[] = [
    'MEDAGE_CY',
];

const percentOfEDUBaseFields : string[] = [
    'ASSCDEG_CY',
    'BACHDEG_CY',
    'GRADDEG_CY',
];

const percentOfRaseBaseFields = [
    'WHITE_CY',
    'BLACK_CY',
    'ASIAN_CY',
    'HISPPOP_CY',
    'AMERIND_CY',
    'PACIFIC_CY',
    'OTHRACE_CY',
    'RACE2UP_CY'
]

const percentOfHouseholdBaseFields = [
    'HINC0_CY',
    'HINC15_CY',
    'HINC25_CY',
    'HINC35_CY',
    'HINC50_CY',
    'HINC75_CY',
    'HINC100_CY',
    'HINC150_CY',
    'HINC200_CY'
];

const precalPercentFields = ['POPGRW10CY', 'POPGRWCYFY'];

const CAExportBufferCSV = (headers: string[], rows: any, lat: number, lng: number, title?: string) => {
    let csv = headers.join(',');

    if (title) {
        csv = `"${title}"\n` + csv;
    }

    csv += '\n';
    csv += `Lat/Lng,${lat} ${lng}\n`;

    Object.keys(rows).forEach((r: any) => {
        const dataName = dataDict[r];
        csv += `"${dataName}",`;
        csv += rows[r].map((r: any) => `"${r}"`).join(',');
        csv += '\n';
    });

    const a = document.createElement('a');
    a.href = 'data:text/tsv;charset=utf-8,' + csv;
    a.setAttribute('style', 'display: none;');
    a.setAttribute('download', `${new Date().toISOString()}_${lat}_${lng}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

const CABufferPopup = () => {

    return new PopupTemplate({
        outFields: ['*'],
        title: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;

            if (atts.bufferValues.indexOf(customBufferValueName) === 0) {
                return 'Custom Buffer Area';
            }
            return `${atts.NAME} at ${atts.lat}, ${atts.lng}`;
        },
        content: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;
            const node = document.createElement('div');
            const root = ReactDOM.createRoot(node);
            const rows: any = {};

            atts.enrichResults.forEach((resultSet: any) => {
                Object.keys(resultSet).forEach(k => {
                    if (k in dataDict) {
                        let value = resultSet[k];

                        if (rawFields.indexOf(k) >= 0) {
                            // let value stay as is
                        } else if (percentOfRaseBaseFields.indexOf(k) >= 0) {
                            const percentValue = (value / resultSet.RACEBASECY) * 100;
                            value = `${Math.round(percentValue * 100) / 100}%`; 
                        } else if (percentOfEDUBaseFields.indexOf(k) >= 0) {
                            const percentValue = (value / resultSet.EDUCBASECY) * 100;
                            value = `${Math.round(percentValue * 100) / 100}%`;
                        } else if (percentOfHouseholdBaseFields.indexOf(k) >= 0) {
                            const percentValue = (value / resultSet.TOTHH) * 100;
                            value = `${Math.round(percentValue * 100) / 100}%`;
                        } else if (dollarFields.indexOf(k) >= 0) {
                            value = `$${format('###,###.', value)}`;
                        } else if (precalPercentFields.indexOf(k) >= 0) {
                            value = `${value}%`;
                        } else if (k !== 'LSF4451') {
                            value = format('###,###.', value);
                        }

                        if (k in rows) {
                            rows[k].push(value);
                        } else {
                            rows[k] = [value];
                        }
                    }
                });
            });

            const headers = atts.enrichResults.map((e: any) => e.bufferDesc);
            headers.unshift('Demo');

            const doCSV = (title?: string) => {
                CAExportBufferCSV(headers, rows, atts.lat, atts.lng, title);
            };

            root.render(
                <CABufferContent feature={feature} doCSV={doCSV} headers={headers} rows={rows} />
            );

            return node;
        }
    });
};

interface ICABufferContentProps {
    doCSV: (title?: string) => void;
    headers: any;
    rows: any;
    feature: __esri.Feature;
}

const CABufferContent: React.FC<ICABufferContentProps> = props => {
    const titleInput = useInputValue('');
    const [reportLoading, setReportLoading] = useState(false);
    const [selectedReport, setSelectedReport] = useState('dandi');
    const [reportTypes, setReportTypes] = useState<Array<{id: string, name: string, category: string}>>([]);
    const [reportsLoading, setReportsLoading] = useState(true);

    useEffect(() => {
        const fetchReportTypes = async () => {
            try {
                const response = await fetch(
                    'https://geoenrich.arcgis.com/arcgis/rest/services/World/geoenrichmentserver/Geoenrichment/Reports/US?f=pjson'
                );
                const data = await response.json();
                
                // Filter out map reports and organize by categories
                const availableReports = data.reports
                    .filter((report: any) => 
                        report.metadata.type !== 'esriReportTemplateMapReport' &&
                        report.formats.includes('pdf')
                    )
                    .map((report: any) => ({
                        id: report.reportID,
                        name: report.metadata.name,
                        category: report.metadata.categories[0]
                    }))
                    .sort((a: any, b: any) => {
                        // Sort first by category, then by name
                        if (a.category === b.category) {
                            return a.name.localeCompare(b.name);
                        }
                        return a.category.localeCompare(b.category);
                    });

                setReportTypes(availableReports);
            } catch (error) {
                console.error('Error fetching report types:', error);
                // Fallback to default report types if fetch fails
                setReportTypes([
                    { id: 'dandi', name: 'Demographic and Income Profile', category: 'Demographic and Income Profile' },
                    { id: 'community_profile', name: 'Community Profile', category: 'Community Profile' }
                ]);
            } finally {
                setReportsLoading(false);
            }
        };

        fetchReportTypes();
    }, []);

    const doReport = async (title?: string) => {
        setReportLoading(true);
        const atts = props.feature.graphic.attributes;

        switch (atts.NAME) {
            case 'Ring Buffer':
                await CARingReport({
                    title,
                    distances: atts.bufferValues,
                    point: new Point({ latitude: atts.lat, longitude: atts.lng }),
                    reportType: selectedReport
                });
                setReportLoading(false);
                break;
            case 'Drivetime Buffer':
                await CADriveReport({
                    title,
                    distances: atts.bufferValues,
                    point: new Point({ latitude: atts.lat, longitude: atts.lng }),
                    reportType: selectedReport
                });
                setReportLoading(false);
                break;
            case customBufferValueName:
                await CACustomReport({
                    graphics: [props.feature.graphic],
                    title,
                    reportType: selectedReport
                });
                setReportLoading(false);
                break;
            default:
                break;
        }
    };

    const handlCSVClick = () => {
        props.doCSV(titleInput.value);
    };

    const renderReportOptions = () => {
        let currentCategory = '';
        let currentGroup: JSX.Element[] = [];
        let result: JSX.Element[] = [];

        // Add main reports group at the top
        const mainReports = reportTypes
            .filter(type => type.id === 'dandi' || type.id === 'community_profile');
        
        if (mainReports.length > 0) {
            result.push(
                <optgroup key="Main Reports" label="Main Reports">
                    {mainReports.map(type => (
                        <option key={`main-${type.id}`} value={type.id}>{type.name}</option>
                    ))}
                </optgroup>
            );
        }

        // Process all reports as normal (including the main ones in their original categories)
        reportTypes.forEach((type) => {
            const option = (
                <option key={type.id} value={type.id}>
                    {type.name}
                </option>
            );

            if (type.category !== currentCategory) {
                if (currentGroup.length > 0) {
                    result.push(
                        <optgroup key={currentCategory} label={currentCategory}>
                            {currentGroup}
                        </optgroup>
                    );
                }
                currentCategory = type.category;
                currentGroup = [option];
            } else {
                currentGroup.push(option);
            }
        });

        return result;
    };

    return (
        <React.Fragment>
            <Input
                className="mb-3"
                type="text"
                placeholder="Report Title"
                onChange={titleInput.onChange}
                value={titleInput.value}
            />
            <div className="d-flex gap-2 align-items-center mb-3">
                <Button color="primary" onClick={handlCSVClick} size="sm">
                    CSV <FontAwesomeIcon icon={faDownload} className="ms-1" />
                </Button>

                <div className="d-flex gap-2 align-items-center flex-grow-1">
                    <Input
                        type="select"
                        value={selectedReport}
                        onChange={(e) => setSelectedReport(e.target.value)}
                        disabled={reportsLoading}
                        className="flex-grow-1"
                    >
                        {reportsLoading ? (
                            <option>Loading reports...</option>
                        ) : (
                            renderReportOptions()
                        )}
                    </Input>

                    <Button
                        color="info"
                        disabled={reportLoading}
                        onClick={() => doReport(titleInput.value)}
                        size="sm"
                    >
                        Report <FontAwesomeIcon
                            icon={reportLoading ? faSpinner : faFilePdf}
                            spin={reportLoading}
                            className="ms-1"
                        />
                    </Button>
                </div>
            </div>
            <Table hover size="sm">
                <thead>
                    <tr>
                        {props.headers.map((header: string, idx: number) => {
                            return <th key={idx}>{header}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(props.rows).map((r: any, idx: number) => {
                        const dataName = dataDict[r];
                        const values = props.rows[r];
                        return (
                            <tr key={idx}>
                                <th scope="row">{dataName}</th>
                                {values.map((v: any, vidx: number) => {
                                    return <td key={vidx}>{v}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default CABufferPopup;
