export const CAEnrichmentVariables = [
    'KeyGlobalFacts.TOTPOP',
    'KeyGlobalFacts.TOTHH',
    'DaytimePopulation.DPOP_CY',
    'Health.MEDHINC_CY',
    'AtRisk.AVGHINC_CY',
    'KeyUSFacts.OWNER_CY',
    'KeyUSFacts.RENTER_CY',
    'KeyUSFacts.VACANT_CY',
    'homevalue.MEDVAL_CY',
    'agebyracebysex.TOTPOP_FY',
    'agebyracebysex.POPGRWCYFY',
    'householdtotals.POPGRW1020',
    'populationtotals.POPGRW1020',
    'HistoricalPopulation.TSPOP10_CY',
    'HistoricalPopulation.TSPOP20_CY',
    'populationtotals.POPDENS_CY',
    'lifemodegroupsNEW.TLIFENAME',
    'AtRisk.TSEGNAME',
    'tapestryhouseholdsNEW.TSEGNUM',
    'lifemodegroupsNEW.TLIFECODE',
    'agebyracebysex.AAGEBASECY',
    'Policy.ASIAN_CY',
    'Policy.BLACK_CY',
    'hispanicorigin.HISPPOP_CY',
    'Policy.WHITE_CY',

    'Policy.OTHRACE_CY',
    'Policy.PACIFIC_CY',
    'Policy.AMERIND_CY',
    'KeyUSFacts.DIVINDX_CY',
    'Policy.RACE2UP_CY',
    'raceandhispanicorigin.RACEBASECY',
    '5yearincrements.MEDAGE_CY',

    'educationalattainment.ASSCDEG_CY',
    'educationalattainment.BACHDEG_CY',
    'educationalattainment.GRADDEG_CY',
    'educationalattainment.EDUCBASECY',

    'householdincome.HINC0_CY',
    'householdincome.HINC15_CY',
    'householdincome.HINC25_CY',
    'householdincome.HINC35_CY',
    'householdincome.HINC50_CY',
    'householdincome.HINC75_CY',
    'householdincome.HINC100_CY',
    'householdincome.HINC150_CY',
    'householdincome.HINC200_CY',
    // 'householdincome.DPOPWRK_CY',
    'food.X1003_X',
    'food.X1003_A',
    'food.X1003_I',
    'industrybynaicscode.N08_SALES',
    // 'retailmarketplace.RTTRDSALES' // retial marketplace appears to be no more
    // 'retailmarketplace.RETTRDPOT'
    // 'retailmarketplace.LSFRETTRD',
    // 'retailmarketplace.RSALES4451',
    // 'retailmarketplace.RETPOT4451',
    // 'retailmarketplace.LSF4451'
    'RetailDemandbyNAICS.IND4451_X',
    'RetailDemandbyNAICS.IND4451_A',
    'RetailDemandbyNAICS.IND4451_I',

    'crime.CRMCYTOTC', 
    'crime.CRMCYPERC',
    'crime.CRMCYPROC',
    'AtRisk.ACSSNAP',
];
