import MapView from "@arcgis/core/views/MapView";
import Widget from "@arcgis/core/widgets/Widget";
import CABasemapWidget from './CABasemapWidget';
import CALayerListSupporting from './CALayerListSupporting';
import CALocateWidget from './CALocateWidget';
import CAMeasureWidget from './CAMeasureWidget';
import CAScaleBarWidget from './CAScaleBarWidget';
import CAScreenshotWidget from './CAScreenshotWidget';
import CASearchWidget from './CASearch';
import CALayerListByFilterWidget from './CALayerListByFilterWidget';
import { bigBoxLayerTitle } from '../layers/cxy/CXBigBoxLayer';
import { chainGroceryTitle } from '../layers/cxy/CXYChainGroceryLayer';
import CALayerListCAWidget from './CALayerListCAWidget';
import CAExportCSVWidget from './CAExportCSVWidget';
import CABufferWidget from './CABufferWidget';
import CALoadingWidget from './CALoadingWidget';
import CALegendWidget from './CALegendWidget';
import { CA_BOTTOM_RIGHT_WIDGET_ID } from '../helpers/CAConstants';
import CAChainGroceryWidget from './CAChainGroceryWidget';
import SceneView from "@arcgis/core/views/SceneView";
import CAEditor from './CAEditor';

interface CAWidget extends Widget {
    group?: string;
}

const initializeTopLeft = (view: MapView) => {
    const basemapGallery = CABasemapWidget(view);
    const locate = CALocateWidget(view);
    const search = CASearchWidget(view);
    const csvDownload = CAExportCSVWidget({ view });
    const loadingWidget = CALoadingWidget(view);
    
    // Initialize editor
    const editorWidgets = CAEditor(view);
    if (editorWidgets) {
        const { editor, toggleButton } = editorWidgets;
        view.ui.add(toggleButton, 'top-left');
        view.ui.add(editor, 'top-left');
    }
    
    const components = [locate, search, basemapGallery, csvDownload];
    components.forEach(c => {
        view.ui.add(c as Widget, {
            position: 'top-left',
            index: 1
        });
    });

    view.ui.add(loadingWidget, {
        position: 'top-left',
        index: 99
    });
};

const initializeTopRight = (view: MapView) => {
    const screenShot = CAScreenshotWidget({ view }) as unknown as Widget;
    view.ui.add(screenShot, 'top-right');
};

const initializeBottomRight = (view: MapView | SceneView) => {
    const bufferWidget = CABufferWidget(view);
    const measureWidget = CAMeasureWidget(view);
    const supportingLayerList = CALayerListSupporting(view);
    const caLayerList = CALayerListCAWidget(view);

    const bigBoxWidget = CALayerListByFilterWidget(
        view,
        bigBoxLayerTitle,
        'organization'
    );

    const chainGroceryWidget = CAChainGroceryWidget(
        view,
        chainGroceryTitle,
        'shopping-cart'
    );

    const bottomRightWidgets = [
        measureWidget,
        bufferWidget,
        supportingLayerList,
        bigBoxWidget,
        chainGroceryWidget,
        caLayerList
    ].filter(w => w instanceof Widget) as CAWidget[];
    
    bottomRightWidgets.forEach(e => {
        e.group = CA_BOTTOM_RIGHT_WIDGET_ID;
    });

    view.ui.add(bottomRightWidgets.reverse(), 'bottom-right');
};

const initializeBottomLeft = (view: MapView) => {
    const scaleBar = CAScaleBarWidget(view);
    const legend = CALegendWidget(view);
    const widgets = [legend, scaleBar].filter(w => w instanceof Widget) as Widget[];
    view.ui.add(widgets, 'bottom-left');
};

export const initializeWidgets = (view: MapView) => {
    initializeTopLeft(view);
    initializeBottomLeft(view);
    initializeBottomRight(view);
    initializeTopRight(view);
}
