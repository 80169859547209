export const WIDGET_BUTTON_CLASS = {
    className: 'esri-widget--button esri-widget esri-interactive',
    role: 'button'
};

export const SUPPORT_LAYER_ID_PREFIX = 'supportLayer_';
export const CA_LAYER_ID_PREFIX = 'caLayer_';

export const CA_COLORS = {
    Primary: [49, 76, 161, 1],
    PrimaryTransparent: [49, 76, 161, 0.15],
    Secondary: [145, 126, 88, 1]
};

export const CA_DEFAULT_BUFFER_VALUES = [1, 3, 5];

export const CA_BOTTOM_RIGHT_WIDGET_ID = 'bottom-right';

export const DEMOGRPAHICS_YEAR_CY = 2023;
export const DEMOGRAPHICS_YEAR_FY = 2028;
