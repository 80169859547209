import { CXYLayerLabels } from '../../helpers/CALayerLabels';
import CXYPopup from '../../popups/CXYPopup';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

export const chainGroceryTitle = 'Chain Grocery';

const CXYChainGroceryLayer = () => {
    const url = 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/Collection_296590/FeatureServer/0'

    const layer = new FeatureLayer({
        url: url,
        title: `${chainGroceryTitle}`,
        visible: true,
        definitionExpression: '1<>1',
        labelsVisible: false,
        legendEnabled: false,
        labelingInfo: [CXYLayerLabels()],
        popupTemplate: CXYPopup(),
    });

    return layer;
};

export default CXYChainGroceryLayer;
