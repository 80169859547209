import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import ColorVariable from "@arcgis/core/renderers/visualVariables/ColorVariable";
import { getSupportingLayerId } from '../../helpers/CALayerID';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Row, Table } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import CASortLayers from '../../helpers/CASortLayers';
import { DEMOGRPAHICS_YEAR_CY, DEMOGRAPHICS_YEAR_FY } from '../../helpers/CAConstants';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import StatisticDefinition from '@arcgis/core/rest/support/StatisticDefinition';
import Color from '@arcgis/core/Color';
import ColorStop from "@arcgis/core/renderers/visualVariables/support/ColorStop";
import * as ReactiveUtils from "@arcgis/core/core/reactiveUtils";

export const mhiCALayerTitle = `Median Household Income (CA)`;


const url = 'https://demographics5.arcgis.com/arcgis/rest/services/USA_Demographics_and_Boundaries_2024/MapServer';

const outFields = [
    'ID',
    'NAME',
    'MEDHINC_CY',
    'MEDHINC_FY',
    'BACHDEG_CY',
    'GRADDEG_CY',
    'POPGRWCYFY',
    'TOTPOP_CY',
    'TOTPOP_FY',
    'TLIFENAME',
    'TSEGNAME',
    'TSEGNUM',
    'EDUCBASECY',
    'BLACK_CY',
    'BLACK_FY',
    'HISPPOP_CY',
    'HISPPOP_FY',
    'POPDENS_CY'
];

export const demoIds = [
    {
        id: 3,
        title: 'State',
        minScale: 147914400,
        maxScale: 18489401
    },
    {
        id: 7,
        title: 'County',
        minScale: 18489400,
        maxScale: 1155601
    },
    {
        id: 9,
        title: 'ZIP Code',
        minScale: 1155600,
        maxScale: 288951
    },
    {
        id: 11,
        title: 'Tract',
        minScale: 288950,
        maxScale: 72501
    },
    {
        id: 12,
        title: 'Block Group',
        minScale: 72500,
        maxScale: 0
    }
]; // Country, State, County, County, County, ZIP, Tract, Block Group



const getDemographicPopupTemplate = () => {
    return new PopupTemplate({
        outFields,
        title: (feature: __esri.Feature) => {
            // NOTE: This is accessing a private property to get the layer name. Double check this still works when updating the Esri API
            const layerName = (feature.graphic as any).sourceLayer.title;

            if (layerName === 'County') {
                return feature.graphic.attributes.NAME;
            }

            if (layerName === 'ZIP Code') {
                return `${feature.graphic.attributes.ID} (${layerName})`;
            }

            return `${feature.graphic.attributes.NAME} (${layerName})`;
        },
        content: (feature: __esri.Feature) => {
            const node = document.createElement('div');
            const root = ReactDOM.createRoot(node);

            const cyPop = parseFloat(feature.graphic.attributes.TOTPOP_CY);
            const fyPop = parseFloat(feature.graphic.attributes.TOTPOP_FY);
            const cyMHI = parseFloat(feature.graphic.attributes.MEDHINC_CY);
            const fyMHI = parseFloat(feature.graphic.attributes.MEDHINC_FY);

            const fourYear = parseFloat(feature.graphic.attributes.BACHDEG_CY);
            const graduate = parseFloat(feature.graphic.attributes.GRADDEG_CY);
            const eduBase = parseFloat(feature.graphic.attributes.EDUCBASECY);
            const collegePercent = ((fourYear + graduate) / eduBase) * 100;

            // AA Population
            const aaCY = parseInt(feature.graphic.attributes.BLACK_CY, 10) * 100;
            const aaFY = parseInt(feature.graphic.attributes.BLACK_FY, 10) * 100;
            const aaPercentCY = aaCY / cyPop;
            const aaPercentFY = aaFY / fyPop;

            // Hisp Population
            const hiCY = parseInt(feature.graphic.attributes.HISPPOP_CY, 10) * 100;
            const hiFY = parseInt(feature.graphic.attributes.HISPPOP_FY, 10) * 100;
            const hiPercentCY = hiCY / cyPop;
            const hiPercentFY = hiFY / fyPop;

            const tapLink = (
                <a
                    href={`https://downloads.esri.com/esri_content_doc/dbl/us/tapestry/segment${feature.graphic.attributes.TSEGNUM}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {feature.graphic.attributes.TSEGNAME}
                </a>
            );

            const content = (
                <React.Fragment>
                    <Row className="mx-0">
                        <p>
                            The dominant tapestry here is {tapLink} which is part of the{' '}
                            {feature.graphic.attributes.TLIFENAME} Lifemode Summary Group.{' '}
                            <strong>
                                <NumericFormat value={collegePercent} decimalScale={2} displayType={'text'} />%
                            </strong>{' '}
                            of the population has attained at least a 4-year degree. The population density is{' '}
                            <strong>{feature.graphic.attributes.POPDENS_CY}</strong> persons/sqmi and the population
                            change is projected to be <strong>{feature.graphic.attributes.POPGRWCYFY}%.</strong>
                        </p>
                    </Row>
                    <Table hover size="sm">
                        <thead>
                            <tr>
                                <th>Demo</th>
                                <th>{DEMOGRPAHICS_YEAR_CY}</th>
                                <th>{DEMOGRAPHICS_YEAR_FY}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Population</th>
                                <td>
                                    <NumericFormat value={cyPop} thousandSeparator={true} displayType={'text'} />
                                </td>
                                <td>
                                    <NumericFormat value={fyPop} thousandSeparator={true} displayType={'text'} />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Black Population %</th>
                                <td>
                                    <NumericFormat value={aaPercentCY} decimalScale={2} displayType={'text'} />%
                                </td>
                                <td>
                                    <NumericFormat value={aaPercentFY} decimalScale={2} displayType={'text'} />%
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Hispanic Population %</th>
                                <td>
                                    <NumericFormat value={hiPercentCY} decimalScale={2} displayType={'text'} />%
                                </td>
                                <td>
                                    <NumericFormat value={hiPercentFY} decimalScale={2} displayType={'text'} />%
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Median Household Income</th>
                                <td>
                                    $
                                    <NumericFormat
                                        value={cyMHI}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        displayType={'text'}
                                    />
                                </td>
                                <td>
                                    $
                                    <NumericFormat
                                        value={fyMHI}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        displayType={'text'}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </React.Fragment>
            );

            root.render(content);

            return node;
        }
    });
};

export const mhiCABreakRenderer = {
    type: 'classBreaks',
    minValue: 0,
    classBreakInfos: [
        {
            classMaxValue: 50000,
            symbol: {
                type: 'esriSFS',
                color: [254, 240, 217],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$0 - $50,000'
        },
        {
            classMaxValue: 75000,
            symbol: {
                type: 'esriSFS',
                color: [253, 204, 138],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$50,000 - $75,000'
        },
        {
            classMaxValue: 100000,
            symbol: {
                type: 'esriSFS',
                color: [252, 141, 89],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$75,000 - $100,000'
        },
        {
            classMaxValue: 125000,
            symbol: {
                type: 'esriSFS',
                color: [227, 74, 51],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$100,000 - $125,000'
        },
        {
            classMaxValue: 9999999,
            symbol: {
                type: 'esriSFS',
                color: [179, 0, 0],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '> $125,000'
        }
    ],
    classificationMethod: 'esriClassifyNaturalBreaks',
    field: 'MEDHINC_CY'
};

const MHICALayerFeatureLayer = (props: {
    title: string;
    rendererJson: any;
    popupTemplate: __esri.PopupTemplate;
}) => {

    const renderer = ClassBreaksRenderer.fromJSON(mhiCABreakRenderer);

    const layers = demoIds.map(d => {
        return new FeatureLayer({
            url: `${url}/${d.id}`,
            title: d.title,
            popupTemplate: props.popupTemplate,
            renderer,
            minScale: d.minScale,
            maxScale: d.maxScale,
            outFields: outFields,
            visible: true
        });
    });

    return new GroupLayer({
        layers,
        listMode: 'hide-children',
        visible: false,
        title: props.title
    });
};

const AsianLayer = () => {

    const aaRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 0.1,
                symbol: {
                    type: 'esriSFS',
                    color: [154, 186, 200, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '0 - 10% Asian'
            },
            {
                classMaxValue: 0.2,
                symbol: {
                    type: 'esriSFS',
                    color: [110, 153, 184, 184],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '10 - 20% Asian'
            },
            {
                classMaxValue: 0.3,
                symbol: {
                    type: 'esriSFS',
                    color: [71, 118, 168, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '20 - 30% Asian'
            },
            {
                classMaxValue: 1,
                symbol: {
                    type: 'esriSFS',
                    color: [36, 81, 135],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '>30% Asian'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'ASIAN_CY',
        normalizationField: 'TOTPOP_CY',
        normalizationType: 'field'
    };

    const popupTemplate = getDemographicPopupTemplate();

    return new GroupLayer({
        title: `Asian Population %`,
        listMode: 'hide-children',
        visible: true,
        layers: demoIds.map(d => 
            new FeatureLayer({
                url: `${url}/${d.id}`,
                renderer: ClassBreaksRenderer.fromJSON(aaRenderer),
                popupTemplate,
                minScale: d.minScale,
                maxScale: d.maxScale,
                title: d.title
            })
        )
    });
};
const AfricanAmericanLayer = () => {

    const aaRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 0.1,
                symbol: {
                    type: 'esriSFS',
                    color: [154, 186, 200, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '0 - 10% Black'
            },
            {
                classMaxValue: 0.2,
                symbol: {
                    type: 'esriSFS',
                    color: [110, 153, 184, 184],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '10 - 20% Black'
            },
            {
                classMaxValue: 0.3,
                symbol: {
                    type: 'esriSFS',
                    color: [71, 118, 168, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '20 - 30% Black'
            },
            {
                classMaxValue: 1,
                symbol: {
                    type: 'esriSFS',
                    color: [36, 81, 135],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '>30% Black'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'BLACK_CY',
        normalizationField: 'TOTPOP_CY',
        normalizationType: 'field'
    };

    const popupTemplate = getDemographicPopupTemplate();

    return new GroupLayer({
        title: `Black Population %`,
        listMode: 'hide-children',
        visible: true,
        layers: demoIds.map(d => 
            new FeatureLayer({
                url: `${url}/${d.id}`,
                renderer: ClassBreaksRenderer.fromJSON(aaRenderer),
                popupTemplate,
                minScale: d.minScale,
                maxScale: d.maxScale,
                title: d.title
            })
        )
    });
};

const HispanicLayer = () => {

    const hispanicRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 0.1,
                symbol: {
                    type: 'esriSFS',
                    color: [237, 248, 251, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '0 - 10% Hispanic'
            },
            {
                classMaxValue: 0.2,
                symbol: {
                    type: 'esriSFS',
                    color: [178, 226, 226, 184],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '10 - 20% Hispanic'
            },
            {
                classMaxValue: 0.3,
                symbol: {
                    type: 'esriSFS',
                    color: [102, 194, 164, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '20 - 30% Hispanic'
            },
            {
                classMaxValue: 1,
                symbol: {
                    type: 'esriSFS',
                    color: [35, 139, 69, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '>30% Hispanic'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'HISPPOP_CY',
        normalizationField: 'TOTPOP_CY',
        normalizationType: 'field'
    };

    const popupTemplate = getDemographicPopupTemplate();

    return new GroupLayer({
        title: `Hispanic Population %`,
        listMode: 'hide-children',
        visible: true,
        layers: demoIds.map(d => 
            new FeatureLayer({
                // url: `https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/Esri_Updated_Demographics_Variables/FeatureServer/${d.id}`,
                url: `${url}/${d.id}`,
                renderer: ClassBreaksRenderer.fromJSON(hispanicRenderer),
                popupTemplate,
                minScale: d.minScale,
                maxScale: d.maxScale,
                title: d.title
            })
        )
    });
};

const tapestryColors = [
    [237, 248, 251], // Light blue
    [179, 205, 227], // Medium blue
    [140, 150, 198], // Blue purple
    [136, 86, 167],  // Purple
    [129, 15, 124],  // Dark purple
    [77, 146, 33],   // Green
    [161, 215, 106], // Light green
    [254, 232, 200], // Light orange
    [253, 187, 132], // Orange
    [227, 74, 51],   // Red orange
    [179, 0, 0],     // Dark red
    [252, 141, 89],  // Coral
    [254, 224, 139], // Light yellow
    [255, 255, 191], // Pale yellow
    [217, 239, 139], // Light lime
    [166, 217, 106], // Lime
    [102, 189, 99],  // Medium green
    [26, 152, 80],   // Dark green
    [145, 207, 96],  // Bright green
    [217, 95, 14],   // Burnt orange
    [254, 153, 41],  // Golden orange
    [204, 76, 2],    // Dark orange
    [140, 45, 4],    // Brown
    [153, 52, 4],    // Dark brown
    [201, 148, 199], // Light purple
    [231, 41, 138],  // Pink
    [206, 18, 86],   // Dark pink
    [152, 0, 67],    // Deep red
    [103, 169, 207], // Sky blue
    [28, 144, 153],  // Teal
    [1, 108, 89],    // Dark teal
    [2, 56, 88],     // Navy
];

const TapestryLayer = () => {
    // Create unique value infos for 67 tapestry segments
    const uniqueValueInfos = Array.from({ length: 67 }, (_, i) => ({
        value: (i + 1).toString(), // TSEGNUM is a string number from 1-67
        symbol: {
            type: 'simple-fill',
            color: [...tapestryColors[i % tapestryColors.length], 255], // Cycle through colors if we have more segments than colors
            outline: {
                type: 'simple-line',
                color: [191, 191, 191, 255],
                width: 1,
                style: 'solid'
            },
            style: 'solid'
        }
    }));

    const uniqueValueRenderer = {
        type: 'unique-value',
        field: 'TSEGNUM', // Use TSEGNUM instead of TSEGNAME for consistent coloring
        defaultSymbol: {
            type: 'simple-fill',
            color: [200, 200, 200, 255],
            outline: {
                type: 'simple-line',
                color: [191, 191, 191, 255],
                width: 1,
                style: 'solid'
            },
            style: 'solid'
        },
        uniqueValueInfos
    };

    const popupTemplate = getDemographicPopupTemplate();

    return new GroupLayer({
        title: 'Tapestry Segments',
        listMode: 'hide-children',
        visible: true,
        layers: demoIds.map(d => 
            new FeatureLayer({
                url: `${url}/${d.id}`,
                renderer: uniqueValueRenderer as any,
                popupTemplate,
                minScale: d.minScale,
                maxScale: d.maxScale,
                title: d.title,
                outFields: outFields
            })
        )
    });
};

const MHILayer = () => {
    const mhiRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 11800,
                symbol: {
                    type: 'esriSFS',
                    color: [254, 240, 217],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$0 - $11,800'
            },
            {
                classMaxValue: 43700,
                symbol: {
                    type: 'esriSFS',
                    color: [253, 204, 138],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$11,800 - $43,700'
            },
            {
                classMaxValue: 75600,
                symbol: {
                    type: 'esriSFS',
                    color: [252, 141, 89],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$43,700 - $75,600'
            },
            {
                classMaxValue: 107500,
                symbol: {
                    type: 'esriSFS',
                    color: [227, 74, 51],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$75,600 - $107,500'
            },
            {
                classMaxValue: 200100,
                symbol: {
                    type: 'esriSFS',
                    color: [179, 0, 0],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$107,500 - $200,100'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'MEDHINC_CY'
    };

    const popupTemplate = getDemographicPopupTemplate();

    return new GroupLayer({
        title: 'Median Household Income',
        listMode: 'hide-children',
        visible: true,
        layers: demoIds.map(d => 
            new FeatureLayer({
                // url: `https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/Esri_Updated_Demographics_Variables/FeatureServer/${d.id}`,
                url: `${url}/${d.id}`,
                renderer: ClassBreaksRenderer.fromJSON(mhiRenderer),
                popupTemplate,
                minScale: d.minScale,
                maxScale: d.maxScale,
                title: d.title
            })
        )
    });

};

const PopulationDensityLayer = () => {
    const popDensityRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 1000,
                symbol: {
                    type: 'esriSFS',
                    color: [254, 240, 217],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '0 - 1,000 people per sq km'
            },
            {
                classMaxValue: 4000,
                symbol: {
                    type: 'esriSFS',
                    color: [253, 204, 138],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '1,000 - 4,000 people per sq km'
            },
            {
                classMaxValue: 22000,
                symbol: {
                    type: 'esriSFS',
                    color: [252, 141, 89],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '4,000 - 22,000 people per sq km'
            },
            {
                classMaxValue: 116000,
                symbol: {
                    type: 'esriSFS',
                    color: [227, 74, 51],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '22,000 - 116,000 people per sq km'
            },
            {
                classMaxValue: 618125,
                symbol: {
                    type: 'esriSFS',
                    color: [179, 0, 0],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '116,000 - 618,125 people per sq km'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'P001_calc_pctPopDensity'
    };

    const renderer = ClassBreaksRenderer.fromJSON(popDensityRenderer);

    return new GroupLayer({
        title: 'Population Density',
        listMode: 'hide-children',
        visible: true,
        layers: demoIds.map((d, index) => 
            new FeatureLayer({
                url: `https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Census_2020_DHC_Total_Population/FeatureServer/${index}`,
                renderer,
                minScale: d.minScale,
                maxScale: d.maxScale,
                title: d.title
            })
        )
    });
};

const CADemographicLayers = (view: __esri.MapView) => {
    const popupTemplate = getDemographicPopupTemplate();

    const mhiCALayer: __esri.Layer = MHICALayerFeatureLayer({
        title: mhiCALayerTitle,
        rendererJson: mhiCABreakRenderer,
        popupTemplate
    });

    const africanAmericanLayer = AfricanAmericanLayer();
    const asianLayer = AsianLayer();
    const hispanicLayer = HispanicLayer();
    const popDensLayer = PopulationDensityLayer();
    const mhiLayer = MHILayer();
    const tapestryLayer = TapestryLayer();

    const title = 'Demographics';

    // const mhiLayerTest = new FeatureLayer({
    //     url: "https://demographics5.arcgis.com/arcgis/rest/services/USA_Demographics_and_Boundaries_2024/MapServer/12",
    //     outFields: ["*"],
    //     title: "MHI (% of View Average)",
    //     visible: true,
    //     opacity: 0.8,
    //     minScale: 0,
    //     maxScale: 0,
    //     popupTemplate: new PopupTemplate({
    //         title: "Median Household Income",
    //         content: [
    //             {
    //                 type: "fields",
    //                 fieldInfos: [
    //                     {
    //                         fieldName: "MEDHINC_CY",
    //                         label: "Median Household Income",
    //                         format: {
    //                             digitSeparator: true,
    //                             places: 0,
    //                             type: "currency"
    //                         }
    //                     },
    //                     {
    //                         fieldName: "expression/pct_of_avg",
    //                         label: "Percent of View Average",
    //                         format: {
    //                             digitSeparator: true,
    //                             places: 0
    //                         }
    //                     }
    //                 ]
    //             }
    //         ],
    //         expressionInfos: [{
    //             name: "pct_of_avg",
    //             title: "Percent of Average",
    //             expression: "$feature.MEDHINC_CY / $view.avgMHI * 100",
    //             returnType: "number"
    //         }]
    //     }),
    //     renderer: new SimpleRenderer({
    //         symbol: new SimpleFillSymbol({
    //             color: [237, 248, 251, 0.6],
    //             outline: {
    //                 color: [128, 128, 128, 0.5],
    //                 width: 0.5
    //             }
    //         }),
    //         visualVariables: [
    //             new ColorVariable({
    //                 field: "MEDHINC_CY",
    //                 // normalizationField: null,
    //                 stops: [
    //                     { value: 0, color: new Color([237, 248, 251]), label: "< 25% of average" },
    //                     { value: 25, color: new Color([237, 248, 251]), label: "< 25% of average" },
    //                     { value: 50, color: new Color([237, 248, 251]), label: "< 50% of average" },
    //                     { value: 100, color: new Color([140, 150, 198]), label: "Average ($100,000)" },
    //                     { value: 125, color: new Color([140, 150, 198]), label: "Average ($100,000)" },
    //                     { value: 150, color: new Color([129, 15, 124]), label: "> 150% of average" },
    //                 ]
    //             })
    //         ]
    //     })
    // });

    // // Setup view-dependent functionality after the layer is added to a view
    // const updateRenderer = async () => {
    //     console.log('updateRenderer');
    //     const query = mhiLayerTest.createQuery();
    //     query.geometry = view.extent;
    //     query.outStatistics = [
    //         new StatisticDefinition({
    //             onStatisticField: "MEDHINC_CY",
    //             outStatisticFieldName: "avgMHI",
    //             statisticType: "avg"
    //         })
    //     ];

    //     const result = await mhiLayerTest.queryFeatures(query);
    //     const avgMHI = result.features[0].attributes.avgMHI;

    //     const renderer = (mhiLayerTest.renderer as __esri.SimpleRenderer).clone();
    //     (renderer.visualVariables[0] as __esri.ColorVariable).stops = [
    //         new ColorStop({ value: avgMHI * 0.5, color: new Color([237, 248, 251]), label: "< 50% of average" }),
    //         new ColorStop({ value: avgMHI, color: new Color([140, 150, 198]), label: "Average" }),
    //         new ColorStop({ value: avgMHI * 1.5, color: new Color([129, 15, 124]), label: "> 150% of average" })
    //     ];
    //     mhiLayerTest.renderer = renderer;
    // };

    // const debouncedUpdateRenderer = (() => {
    //     let timeoutId: NodeJS.Timeout;
    //     return () => {
    //         clearTimeout(timeoutId);
    //         timeoutId = setTimeout(() => {
    //             updateRenderer();
    //         }, 300); // 300ms debounce delay
    //     };
    // })();

    // ReactiveUtils.watch(
    //     () => view.extent,
    //     () => debouncedUpdateRenderer(),
    //     { initial: true }
    // );

    const layers = [
        mhiCALayer,
        popDensLayer,
        mhiLayer,
        africanAmericanLayer,
        asianLayer,
        hispanicLayer,
        tapestryLayer
    ];

    layers.forEach(l => {
        l.id = `demo_${l.title}`;
    });

    return new GroupLayer({
        layers: layers.sort(CASortLayers),
        title,
        visible: false,
        visibilityMode: 'exclusive',
        id: getSupportingLayerId(title),
        opacity: 0.5
    });
};

export default CADemographicLayers;
