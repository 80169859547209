import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { CALayerLabels } from '../../helpers/CALayerLabels';
import CAInfoPopup from '../../popups/CAInfoPopup';
import { getCALayerId } from '../../helpers/CALayerID';

export const CAInfoURL = 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CA_Info/FeatureServer/0';

const CAInfoLayer = () => {
    const title = 'CA Info';

    return new FeatureLayer({
        title,
        id: getCALayerId(title),
        visible: true,
        url: CAInfoURL,
        labelsVisible: false,
        labelingInfo: [CALayerLabels()],
        popupTemplate: CAInfoPopup(),
        legendEnabled: false
    });
};

export default CAInfoLayer;
