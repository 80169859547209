import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { getSupportingLayerId } from '../../helpers/CALayerID';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import Graphic from '@arcgis/core/Graphic';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import Color from '@arcgis/core/Color';

export const parcelsLayerTitle = 'Parcels';

interface ParcelAttributes {
    [key: string]: any;
    trans_date?: number;
    sale_price?: string;
    robust_id: string;
}

// Export the owner label class for reuse
export const parcelOwnerLabelClass = new LabelClass({
    labelExpressionInfo: {
        expression: "$feature.owner"
    },
    symbol: {
        type: "text",
        color: new Color([0, 0, 0, 1]),
        haloColor: new Color([255, 255, 255, 1]),
        haloSize: 1,
        font: {
            size: 10,
            family: "Arial",
            weight: "normal"
        }
    },
    minScale: 36110,
    maxScale: 0
});

const CAParcelsLayer = () => {
    const title = parcelsLayerTitle;

    const downloadCSV = (feature: Graphic) => {
        const attributes = feature.attributes as ParcelAttributes;
        const csvContent = Object.entries(attributes)
            .map(([key, value]) => {
                // Format special fields
                if (key === 'trans_date' && value) {
                    value = new Date(value).toLocaleDateString();
                }
                if (key === 'sale_price' && value) {
                    value = `$${parseFloat(value).toLocaleString()}`;
                }
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }
                return `${key},${value}`;
            })
            .join('\n');

        const blob = new Blob([`Field,Value\n${csvContent}`], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = url;
        a.download = `parcel_${attributes.robust_id}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const popupTemplate = new PopupTemplate({
        title: '{owner}',
        content: [
            {
                type: 'custom',
                creator: (event: any) => {
                    const div = document.createElement('div');
                    const root = ReactDOM.createRoot(div);
                    
                    root.render(
                        React.createElement('div', { className: 'text-right mt-3' },
                            React.createElement('button', {
                                className: 'btn btn-sm btn-primary',
                                onClick: () => downloadCSV(event.graphic),
                                title: 'Download all fields as CSV'
                            }, [
                                React.createElement(FontAwesomeIcon, {
                                    icon: faDownload,
                                    className: 'me-2'
                                }),
                                'Download CSV'
                            ])
                        )
                    );
                    
                    return div;
                }
            },
            {
                type: 'fields',
                fieldInfos: [
                    // Site Address Information
                    {
                        fieldName: 'address',
                        label: 'Site Address'
                    },
                    {
                        fieldName: 'addr_number',
                        label: 'Site House Number'
                    },
                    {
                        fieldName: 'addr_street_prefix',
                        label: 'Site Street Prefix'
                    },
                    {
                        fieldName: 'addr_street_name',
                        label: 'Site Street Name'
                    },
                    {
                        fieldName: 'addr_street_suffix',
                        label: 'Site Street Suffix'
                    },
                    {
                        fieldName: 'addr_street_type',
                        label: 'Site Street Type'
                    },
                    {
                        fieldName: 'addr_city',
                        label: 'Site City'
                    },
                    {
                        fieldName: 'addr_zip',
                        label: 'Site Zip Code'
                    },
                    {
                        fieldName: 'addr_zipplusfour',
                        label: 'Site Zip+4 Part'
                    },
                    {
                        fieldName: 'census_zip',
                        label: 'Site Census Zip Code'
                    },

                    // Mailing Information
                    {
                        fieldName: 'mail_name',
                        label: 'Mailing Name'
                    },
                    {
                        fieldName: 'mail_recipient',
                        label: 'Mailing Address Recipient'
                    },
                    {
                        fieldName: 'mail_address1',
                        label: 'Mailing Address Line 1'
                    },
                    {
                        fieldName: 'mail_address2',
                        label: 'Mailing Address Line 2'
                    },
                    {
                        fieldName: 'mail_address3',
                        label: 'Mailing Address Line 3'
                    },
                    {
                        fieldName: 'mail_uspsbox',
                        label: 'Mailing Address USPS Box'
                    },
                    {
                        fieldName: 'mail_placename',
                        label: 'Mailing Address Place Name'
                    },
                    {
                        fieldName: 'mail_statename',
                        label: 'Mailing Address State Name'
                    },
                    {
                        fieldName: 'mail_zipcode',
                        label: 'Mailing Address Zip Code'
                    },
                    {
                        fieldName: 'mail_countryname',
                        label: 'Mailing Address Country Name'
                    },

                    // Property Information
                    {
                        fieldName: 'owner_occupied',
                        label: 'Owner Occupied'
                    },
                    {
                        fieldName: 'usps_residential',
                        label: 'USPS Residential'
                    },
                    {
                        fieldName: 'trans_date',
                        label: 'Transfer Date',
                        format: {
                            dateFormat: 'short-date'
                        }
                    },
                    {
                        fieldName: 'sale_price',
                        label: 'Sale Price',
                        format: {
                            digitSeparator: true,
                            places: 2,
                            type: 'currency'
                        }
                    },
                    {
                        fieldName: 'mkt_val_land',
                        label: 'Land Market Value',
                        format: {
                            digitSeparator: true,
                            places: 2,
                            type: 'currency'
                        }
                    },
                    {
                        fieldName: 'mkt_val_bldg',
                        label: 'Improvement Market Value',
                        format: {
                            digitSeparator: true,
                            places: 2,
                            type: 'currency'
                        }
                    },
                    {
                        fieldName: 'mkt_val_tot',
                        label: 'Total Market Value',
                        format: {
                            digitSeparator: true,
                            places: 2,
                            type: 'currency'
                        }
                    },

                    // Building Information
                    {
                        fieldName: 'buildings',
                        label: 'Num Buildings'
                    },
                    {
                        fieldName: 'bldg_sqft',
                        label: 'Building Area SqFt',
                        format: {
                            digitSeparator: true,
                            places: 0
                        }
                    },
                    {
                        fieldName: 'year_built',
                        label: 'Year Built'
                    },
                    {
                        fieldName: 'story_height',
                        label: 'Story Height',
                        format: {
                            digitSeparator: true,
                            places: 1
                        }
                    },

                    // Land Information
                    {
                        fieldName: 'land_use_code',
                        label: 'Land Use Code'
                    },
                    {
                        fieldName: 'land_use_class',
                        label: 'Land Use Class'
                    },
                    {
                        fieldName: 'acreage_deeded',
                        label: 'Deeded Acreage',
                        format: {
                            digitSeparator: true,
                            places: 4
                        }
                    },
                    {
                        fieldName: 'acreage_calc',
                        label: 'Calculated Acreage',
                        format: {
                            digitSeparator: true,
                            places: 4
                        }
                    },
                    {
                        fieldName: 'acreage_adjacent_with_sameowner',
                        label: 'Acreage Adjacent With Same Owner',
                        format: {
                            digitSeparator: true,
                            places: 4
                        }
                    },
                    {
                        fieldName: 'land_cover',
                        label: 'Land Cover'
                    },
                    {
                        fieldName: 'crop_cover',
                        label: 'Crop Cover'
                    },

                    // Legal Information
                    {
                        fieldName: 'legal_desc1',
                        label: 'Legal Description 1'
                    },
                    {
                        fieldName: 'legal_desc2',
                        label: 'Legal Description 2'
                    },
                    {
                        fieldName: 'legal_desc3',
                        label: 'Legal Description 3'
                    },

                    // Administrative Information
                    {
                        fieldName: 'county_id',
                        label: 'County Id'
                    },
                    {
                        fieldName: 'cty_row_id',
                        label: 'County Row Id'
                    },
                    {
                        fieldName: 'county_name',
                        label: 'County Name'
                    },
                    {
                        fieldName: 'muni_name',
                        label: 'Municipality Name'
                    },
                    {
                        fieldName: 'census_place',
                        label: 'Place'
                    },
                    {
                        fieldName: 'state_abbr',
                        label: 'State Abbreviation'
                    },
                    {
                        fieldName: 'school_district',
                        label: 'School District'
                    },
                    {
                        fieldName: 'census_block',
                        label: 'Census Block'
                    },
                    {
                        fieldName: 'census_tract',
                        label: 'Census Tract'
                    },
                    {
                        fieldName: 'section_township_range',
                        label: 'PLSS Section Township Range'
                    },

                    // Zoning Information
                    {
                        fieldName: 'fld_zone',
                        label: 'Flood Zone(s)'
                    },
                    {
                        fieldName: 'zone_subty',
                        label: 'Flood Zone Subtype(s)'
                    },
                    {
                        fieldName: 'zoning',
                        label: 'Zoning'
                    },

                    // Geographic Information
                    {
                        fieldName: 'latitude',
                        label: 'Latitude',
                        format: {
                            digitSeparator: true,
                            places: 6
                        }
                    },
                    {
                        fieldName: 'longitude',
                        label: 'Longitude',
                        format: {
                            digitSeparator: true,
                            places: 6
                        }
                    },
                    {
                        fieldName: 'elevation',
                        label: 'Elevation (Ft)',
                        format: {
                            digitSeparator: true,
                            places: 1
                        }
                    },

                    // Reference Information
                    {
                        fieldName: 'parcel_id',
                        label: 'Parcel Id'
                    },
                    {
                        fieldName: 'alt_id_1',
                        label: 'Alternate Id 1'
                    },
                    {
                        fieldName: 'alt_id_2',
                        label: 'Alternate Id 2'
                    },
                    {
                        fieldName: 'county_link',
                        label: 'County Link'
                    },
                    {
                        fieldName: 'robust_id',
                        label: 'Robust Id'
                    },
                    {
                        fieldName: 'last_updated',
                        label: 'Last Updated'
                    }
                ]
            }
        ],
        outFields: ['*']
    });

    const layer = new FeatureLayer({
        url: 'https://reportallusa.com/client=zM1fWADTJz/rest/services/Parcels/FeatureServer/0',
        title,
        id: getSupportingLayerId(title),
        visible: false,
        minScale: 36110,
        maxScale: 0,
        opacity: 0.7,
        popupTemplate,
    });

    return layer;
};

export default CAParcelsLayer;
