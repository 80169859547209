import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { Button, Form, FormGroup, Label, Input, ButtonGroup } from 'reactstrap';
import { useInputValue } from '../helpers/CAHooks';
import { CADriveBuffer, CARingBuffer, CACustomBuffer } from '../helpers/CABufferHelpers';
import { bufferLayerTitle } from '../layers/supporting/CABufferLayer';
import { CADrawPolygon } from '../helpers/CADrawHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import Draw from '@arcgis/core/views/draw/Draw';
import Expand from '@arcgis/core/widgets/Expand';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBan } from '@fortawesome/free-solid-svg-icons';
// import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const CABuffer: React.FC<{
    view: __esri.MapView | __esri.SceneView;
}> = props => {
    const defaultMessage = 'Enter values and/or choose a tool.';
    const clickPointMessage = 'Click a point...';
    const calculatingMessage = 'Calculating...';
    const customAreaMessage = 'Draw a custom area. Double click to complete.';
    const errorMessage = 'An error occured.';
    const minCustomScale = 1000000;

    const bufferInput = useInputValue('');
    const [bufferValues, setBufferValues] = useState<number[]>();
    const [message, setMessage] = useState(defaultMessage);
    const [disableInput, setDisableInput] = useState(false);
    const [bufferType, setBufferType] = useState<'ring' | 'drive' | 'custom' | undefined>();
    const [disableCustom, setDisableCustom] = useState(props.view.scale > minCustomScale ? true : false);

    useEffect(() => {
        const handle = reactiveUtils.watch(
            () => props.view.scale,
            (scale: number) => {
                setDisableCustom(scale > minCustomScale);
            }
        );

        return () => {
            handle?.remove();
        };
    }, [props.view]);

    const draw = useMemo(() => {
        return new Draw({ view: props.view });
    }, [props.view]);

    useEffect(() => {
        if (bufferInput.value) {
            let values = bufferInput.value.split(',');
            const newBufferValues: number[] = [];

            if (values.length) {
                values.forEach(v => {
                    const bufferNumber = parseFloat(v);
                    if (!isNaN(bufferNumber)) {
                        newBufferValues.push(bufferNumber);
                    }
                });
                setBufferValues([...newBufferValues]);
            } else {
                setBufferValues([]);
            }
        } else {
            setBufferValues([]);
        }
    }, [bufferInput.value]);

    const setCrosshair = () => {
        (props.view.container as HTMLElement).classList.add('crosshair');
    };

    const removeCrosshair = useCallback(() => {
        (props.view.container as HTMLElement).classList.remove('crosshair');
    }, [props.view]);

    const completeResults = useCallback(
        (success: boolean) => {
            if (success) {
                setMessage(defaultMessage);
            } else {
                setMessage(errorMessage);
            }

            bufferInput.setValue('');
            setDisableInput(false);
            setBufferType(undefined);
            removeCrosshair();
        },
        [bufferInput, removeCrosshair]
    );

    const doCustom = useCallback(
        (graphic: __esri.Graphic) => {
            setMessage(calculatingMessage);
            const doCustomCalc = async () => {
                const bufferSuccess = await CACustomBuffer(props.view, graphic);
                completeResults(bufferSuccess);
                completeResults(true);
            };

            doCustomCalc();
        },
        [completeResults, props.view]
    );

    const doDrive = useCallback(
        (evt: __esri.ViewClickEvent) => {
            setMessage(calculatingMessage);
            const doRingCalc = async () => {
                if (bufferValues && bufferValues.length > 0) {
                    const bufferSuccess = await CADriveBuffer(evt.mapPoint, bufferValues, props.view);
                    completeResults(bufferSuccess);
                    completeResults(true);
                }
            };
            doRingCalc();
        },
        [bufferValues, completeResults, props.view]
    );

    const doRing = useCallback(
        (evt: __esri.ViewClickEvent) => {
            setMessage(calculatingMessage);
            const doRingCalc = async () => {
                if (bufferValues && bufferValues.length > 0) {
                    const bufferSuccess = await CARingBuffer(evt.mapPoint, bufferValues, props.view);
                    completeResults(bufferSuccess);
                    completeResults(true);
                }
            };
            doRingCalc();
        },
        [bufferValues, completeResults, props.view]
    );

    const handleDriveClick = () => {
        setCrosshair();
        setMessage(clickPointMessage);
        setDisableInput(true);
        setBufferType('drive');
    };

    const handleRingClick = () => {
        setCrosshair();
        setMessage(clickPointMessage);
        setDisableInput(true);
        setBufferType('ring');
    };

    const handleClearClick = () => {
        removeCrosshair();
        draw.reset();
        props.view.graphics.removeAll();
        const bufferLayer = props.view.map.layers.find(l => l.title === bufferLayerTitle) as __esri.GraphicsLayer;
        bufferLayer.graphics.removeAll();
        completeResults(true);
        setBufferType(undefined);
    };

    const handleCustomClick = () => {
        setCrosshair();
        setMessage(customAreaMessage);
        setDisableInput(true);
        setBufferType('custom');
        // const bufferLayer = props.view.map.layers.find(l => l.title === bufferLayerTitle) as __esri.GraphicsLayer;

        const action = draw.create('polygon');

        props.view.focus();

        action.on('vertex-add', (evt: __esri.PolygonDrawAction) => {
            CADrawPolygon(
                evt,
                props.view,
                false
            );
        });

        action.on('cursor-update', (evt: __esri.PolygonDrawAction) => {
            CADrawPolygon(
                evt,
                props.view,
                false,
            );
        });

        action.on('vertex-remove', (evt: __esri.PolygonDrawAction) => {
            CADrawPolygon(
                evt,
                props.view,
                false
            );
        });

        action.on('draw-complete', async (evt: __esri.PolygonDrawAction) => {
            const customPolygon = await CADrawPolygon(
                evt,
                props.view,
                true
            );

            if (customPolygon) {
                doCustom(customPolygon);
            }

            draw.reset();
        });
    };

    useEffect(() => {
        let clickHandler: IHandle;
        if (bufferType === 'ring' || bufferType === 'drive') {
            clickHandler = props.view.on('click', evt => {
                evt.stopPropagation();
                switch (bufferType) {
                    case 'ring':
                        try {
                            doRing(evt);
                        } catch (error) {
                            setMessage(errorMessage);
                        }
                        break;
                    case 'drive':
                        try {
                            doDrive(evt);
                        } catch (error) {
                            setMessage(errorMessage);
                        }
                        break;
                    default:
                        completeResults(true);
                        break;
                }
            });
        }

        return () => {
            if (clickHandler) {
                clickHandler?.remove();
            }
        };
    }, [bufferType, props.view, completeResults, doDrive, doRing]);

    return (
        <div className="esri-widget esri-layer-list esri-widget--panel m-2">
            <Form style={{ width: '100%' }}>
                <FormGroup>
                    <Label>Buffer Values (comma seperated)</Label>
                    <Input
                        type="text"
                        disabled={disableInput}
                        value={bufferInput.value}
                        onChange={bufferInput.onChange}
                    />
                </FormGroup>
                <div className="d-flex justify-content-between">
                    <ButtonGroup size="sm">
                        <Button
                            disabled={bufferValues?.length ? false : true}
                            outline={bufferValues?.length ? false : true}
                            color="primary"
                            onClick={handleRingClick}
                        >
                            Ring
                        </Button>
                        <Button
                            disabled={bufferValues?.length ? false : true}
                            outline={bufferValues?.length ? false : true}
                            color="info"
                            onClick={handleDriveClick}
                        >
                            Drive
                        </Button>
                        <Button
                            disabled={disableCustom}
                            outline={disableCustom ? true : false}
                            color={disableCustom ? 'secondary' : 'warning'}
                            onClick={handleCustomClick}
                        >
                            Custom
                        </Button>
                    </ButtonGroup>
                    <Button color="danger" size="sm" onClick={handleClearClick}>
                        Clear
                        <FontAwesomeIcon icon={faBan} className="ms-1" />
                    </Button>
                </div>
                <div className="my-2">{message}</div>
            </Form>
        </div>
    );
};

const CABufferWidget = (view: __esri.MapView | __esri.SceneView) => {

    const node = document.createElement('div');
    const root = ReactDOM.createRoot(node);

    root.render(<CABuffer view={view} />);

    return new Expand({
        view,
        content: node,
        expandTooltip: 'Buffer',
        expandIcon: 'rings'
    });
};

export default CABufferWidget;
