import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import { getSupportingLayerId } from '../../helpers/CALayerID';
import { Bar } from 'react-chartjs-2';
import React from 'react';
import ReactDOM from 'react-dom/client';
import format from 'number-format.js';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const stops = [
    {
        value: 10000,
        size: 12,
        label: '< 10,000'
    },
    {
        value: 30000,
        size: 15,
        label: '10,000 - 30,000'
    },
    {
        value: 90000,
        size: 19,
        label: '30,000 - 90,000'
    },
    {
        value: 120000,
        size: 24,
        label: '90,000 - 120,000'
    },
    {
        value: 150000,
        size: 30,
        label: '120,000 - 150,000'
    },
    {
        value: 180000,
        size: 37,
        label: '150,000 - 180,000'
    },
    {
        value: 180001,
        size: 46,
        label: '> 180,000'
    }
];

const markerSymbol = {
    type: 'simple-marker',
    color: [229, 139, 72, 191],
    outline: {
        color: [229, 139, 72, 255],
        width: 0.5
    }
};

const renderer = {
    type: 'simple',
    symbol: markerSymbol,
    visualVariables: [
        {
            type: 'size',
            field: 'Traffic1',
            stops: stops
        }
    ]
};

const CAFTrafficLayer = () => {
    const title = 'Traffic Counts';

    const textSymbol = new TextSymbol({
        color: [0, 0, 0, 1],
        haloColor: [255, 255, 255, 1],
        yoffset: -4.5,
        haloSize: 3,
        font: {
            size: 9,
            weight: 'bold'
        }
    });

    const popupTemplate = new PopupTemplate({
        outFields: ['*'],
        title,
        content: (feature: __esri.Feature) => {
            const node = document.createElement('div');
            const root = ReactDOM.createRoot(node);

            const yearIndex = [1, 2, 3, 4, 5]; // 1 is most recent
            const counts: number[] = [];
            const labels: string[] = [];

            yearIndex.forEach(y => {
                const keyCount = `Traffic${y}`;
                const keyType = `Cnttype${y}`;
                const keyYear = `Cnt${y}year`;
                const count = parseInt(`${feature.graphic.attributes[keyCount]}`, 10);

                if (count && count > 0) {
                    counts.push(count);
                    labels.push(`${feature.graphic.attributes[keyYear]} ${feature.graphic.attributes[keyType]}`);
                }
            });

            let chartTitle = `${feature.graphic.attributes.Street}`;

            if (feature.graphic.attributes.CrossSt) {
                let dir = '';

                if (feature.graphic.attributes.CrossDir !== ' ') {
                    dir = ` ${feature.graphic.attributes.CrossDir}`;
                }

                chartTitle += ` & ${feature.graphic.attributes.CrossSt}${dir}`;
            }

            const data = {
                labels,
                datasets: [
                    {
                        label: 'Count',
                        backgroundColor: [
                            'rgba(49, 76, 161, 0.7)',
                            'rgba(49, 76, 161, 0.7)',
                            'rgba(49, 76, 161, 0.7)',
                            'rgba(49, 76, 161, 0.7)',
                            'rgba(49, 76, 161, 0.7)'
                        ],
                        borderColor: [
                            'rgba(49, 76, 161, 1)', // Consolidated Affiliates blue
                            'rgba(49, 76, 161, 1)',
                            'rgba(49, 76, 161, 1)',
                            'rgba(49, 76, 161, 1)',
                            'rgba(49, 76, 161, 1)'
                        ],
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(78, 183, 74, 1)',
                        hoverBorderColor: 'rgba(78, 183, 74, 1)',
                        data: counts
                    }
                ]
            };

            const trafficChart = (
                <Bar
                    data={data}
                    width={300}
                    height={120}
                    options={{
                        indexAxis: 'y',
                        aspectRatio: 2.5,
                        plugins: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: chartTitle,
                                color: 'rgba(50, 50, 50, 1)',
                                font: {
                                    size: 12
                                }
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context: any) {
                                        const value = context.raw;
                                        if (value === 0) {
                                            return '0';
                                        }
                                        return format('###,###.', value);
                                    }
                                }
                            }
                        },
                        scales: {
                            x: {
                                beginAtZero: true,
                                ticks: {
                                    callback: function(tickValue: number | string) {
                                        const value = Number(tickValue);
                                        if (value === 0) {
                                            return '0';
                                        }
                                        return format('###,###.', value);
                                    }
                                }
                            },
                            y: {
                            }
                        }
                    }}
                />
            );

            root.render(<React.Fragment>{trafficChart}</React.Fragment>);

            return node;
        }
    });

    const trafficLayer = new FeatureLayer({
        id: getSupportingLayerId(title),
        minScale: 250000,
        opacity: 0.75,
        popupTemplate: popupTemplate,
        portalItem: {
            id: '70507a8779a2470b89c6a8c90394d68e'
        },
        renderer: renderer as any,
        title,
        visible: false,
        labelingInfo: [
            {
                labelExpressionInfo: {
                    expression: 'Text(Number($feature.Traffic1), "#,###")'
                },
                symbol: textSymbol,
                labelPlacement: 'center-right',
                minScale: 25000
            }
        ]
    });

    return trafficLayer;
};

export default CAFTrafficLayer;
