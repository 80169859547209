import Legend from '@arcgis/core/widgets/Legend';
import Expand from '@arcgis/core/widgets/Expand';
import type MapView from '@arcgis/core/views/MapView';
import type SceneView from '@arcgis/core/views/SceneView';

const CALegendWidget = (view: MapView | SceneView) => {
    const legend = new Legend({
        view: view
    });

    return new Expand({
        view: view,
        content: legend,
        expandTooltip: 'Legend'
    });
};

export default CALegendWidget;
